import { PaymentMethodType, PickupPaymentStatusType } from 'app/models';
import * as Yup from 'yup';

export type BasicFormItem = {
  userid: number | null;
  displayName: string;
  box: number | null;
  expectedDeliveryDate: Date | Date[] | null;
  isEditable: boolean;
  paymentMethod?: PaymentMethodType | string;
  receiptImages?: any;
  clientRemarks?: string;
  cost?: number | null | '';
  passcode?: null | string;
  pickupDate?: Date | Date[] | null;
  lockerNumber: string;
  paymentStatus: PickupPaymentStatusType | '';
};

export const BASIC_FORM_ITEM = {
  userid: null,
  displayName: '',
  Cellar: [],
  box: null,
  expectedDeliveryDate: null,
  isEditable: false,
  paymentMethod: '',
  receiptImages: [],
  clientRemarks: '',
  cost: null,
  passcode: '',
  pickupDate: null,
  lockerNumber: '',
  paymentStatus: '' as PickupPaymentStatusType,
};

export const BasicSchema = Yup.object().shape({
  userid: Yup.number().nullable().required('必填項目'),
  displayName: Yup.string()
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元')
    .required('必填項目'),
  box: Yup.number().min(1, '不可少於一箱').nullable().required('必填項目'),
  expectedDeliveryDate: Yup.date().nullable().required('必填項目'),
});

export type DraftedFormItem = {
  cost: number | null | '';
  passcode: null | string;
  pickupDate: Date | Date[] | null;
  images?: any;
  remarks?: string;
  isEditable: boolean;
  paymentStatus: PickupPaymentStatusType | '';
  receiptImages?: any;
  lockerNumber: string;
  isFree: boolean;
};

export const DRAFTED_FORM_ITEM = {
  cost: null,
  passcode: '',
  pickupDate: null,
  images: [],
  remarks: '',
  isEditable: false,
  paymentStatus: '' as PickupPaymentStatusType,
  receiptImages: [],
  lockerNumber: '',
  isFree: false,
};

export const DraftedSchema = Yup.object().shape({
  cost: Yup.number()
    .nullable()
    .max(999999, '不可大於HKD 999,999.00')
    .when('isFree', (isFree, passSchema) =>
      isFree
        ? passSchema
        : passSchema.min(4, '不可少於HKD 4').required('必填項目'),
    ),
  passcode: Yup.string()
    .nullable()
    .min(1, '最少要2個字元')
    .required('必填項目'),
  pickupDate: Yup.date().nullable().required('必填項目'),
  lockerNumber: Yup.string()
    .min(1, '最少要1個字元')
    .nullable()
    .max(100, '不可多於100個字元')
    .required('必填項目'),
  remarks: Yup.string()
    .min(2, '少要2個字元')
    .nullable()
    .max(500, '不可多於500個字元'),
});

export type StatusFormItem = {
  images?: any;
  paymentStatus: string;
  remarks: string;
};

export const STATUS_FORM_ITEM = {
  images: [],
  paymentStatus: '',
  remarks: '',
};

export const StatusSchema = Yup.object().shape({
  paymentStatus: Yup.string()
    .nullable()
    .oneOf(
      ['InProgress', 'Completed', 'AdminCompleted', 'AdminCanceled'],
      '錯誤的狀態',
    )
    .required('必填項目'),
  remarks: Yup.string()
    .min(2, '少要2個字元')
    .nullable()
    .max(500, '不可多於500個字元'),
});

export enum DraftedPickupPaymentStatusOptions {
  // 'InProgress' = '待確認單據',
  'Completed' = '待客戶取貨',
  'AdminCompleted' = '客戶已取貨',
  'AdminCanceled' = '收貨取消',
}

// export type InprogressFormItem = {
//   cost: number | null;
//   passcode: null | string;
//   pickupDate: Date | Date[] | null;
//   images?: any;
//   remarks?: string;
//   receiptImages?: any;
// };

// export const INPROGRESS_FORM_ITEM = {
//   cost: null,
//   passcode: '',
//   pickupDate: null,
//   images: [],
//   remarks: '',
//   receiptImages: [],
// };

// export const InprogressSchema = Yup.object().shape({
//   cost: Yup.number()
//     .nullable()
//     .min(4, '不可少於HKD 4')
//     .max(999999, '不可大於HKD 999,999.00')
//     .required('必填項目'),
//   passcode: Yup.string()
//     .nullable()
//     .min(1, '最少要2個字元')
//     .required('必填項目'),
//   pickupDate: Yup.date().nullable().required('必填項目'),
//   remarks: Yup.string()
//     .min(2, '少要2個字元')
//     .nullable()
//     .max(500, '不可多於500個字元'),
// });

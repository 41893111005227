import React from 'react';
import moment from 'moment';
import { Badge } from 'reactstrap';
import { StripeLog } from 'app/models';
import { convertToClientPrice, formatPrice } from 'app/helpers/CommonHelper';
import { renderTransportPaymentStatus } from 'app/helpers/TransportHelper';

const StripeLogsColumns = () => [
  {
    text: '付款金額',
    dataField: 'amount',
    sort: false,
    formatter: (log: StripeLog) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {formatPrice(convertToClientPrice(log.raw.data.object.amount)) ||
              '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '付款狀態',
    dataField: 'status',
    sort: false,
    formatter: (log: StripeLog) => (
      <>
        {log.raw.type === 'payment_intent.created'
          ? renderTransportPaymentStatus('Pending')
          : null}
        {log.raw.type === 'payment_intent.succeeded'
          ? renderTransportPaymentStatus('Completed')
          : null}
        {log.raw.type === 'payment_intent.payment_failed' ? (
          <Badge pill className="badge-soft-secondary ms-1 p-1 font-size-12">
            失敗
          </Badge>
        ) : null}
      </>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    sort: false,
    formatter: (log: StripeLog) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(log.createdAt).format('DD-MM-YYYY')}
          </div>
        </h5>
      </>
    ),
  },
];

export default StripeLogsColumns;

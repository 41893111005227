import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
  Modal,
} from 'reactstrap';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'app/components/Form';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import {
  createCategory,
  deleteOneCategory,
  getOneCategory,
  updateOneCategory,
} from 'app/services/PromotionService';
import { changePreloader } from 'store/Layout';
import { APP_NAME } from 'app/config';
import { LocaleStr } from 'app/models';

const Schema = Yup.object().shape({
  name: Yup.string()
    .min(2, '最少要2個字元')
    .nullable()
    .max(10, '不可多於10個字元')
    .required('必填項目'),
  nameEN: Yup.string()
    .min(2, '最少要2個字元')
    .nullable()
    .max(20, '不可多於50個字元'),
});

type FormItem = {
  name: string;
  nameEN: string;
  nameCN: string;
};

const FORM_ITEM = {
  name: '',
  nameEN: '',
  nameCN: '',
};

interface AdsCategoryFormPageQuery {
  categoryid?: string;
}

export const AdsCategoryFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<AdsCategoryFormPageQuery>();
  const [categoryid, setcategoryid] = useState<number | null>(null);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  const initForm = useCallback(async () => {
    const { categoryid: categoryID } = params;
    try {
      if (categoryID) {
        dispatch(changePreloader(true));
        const adsCategoryRes = await getOneCategory(categoryID);
        const { categoryid, name, CategoryLocs } = adsCategoryRes;
        setcategoryid(categoryid);
        const index = _.findIndex(
          CategoryLocs,
          loc => loc.locale === LocaleStr[0],
        );
        const jndex = _.findIndex(
          CategoryLocs,
          loc => loc.locale === LocaleStr[1],
        );
        const newForm: FormItem = {
          name,
          nameEN: index > -1 ? CategoryLocs[index].name : '',
          nameCN: jndex > -1 ? CategoryLocs[jndex].name : '',
        };
        setForm(newForm);
        dispatch(changePreloader(false));
      }
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const createNewCategory = async (values: FormItem) => {
    setLoading(true);
    try {
      const { name, nameEN, nameCN } = values;
      const params = {
        name,
        CategoryLocs: [
          {
            name: nameEN,
            locale: LocaleStr[0],
          },
          {
            name: nameCN,
            locale: LocaleStr[1],
          },
        ],
      };
      await createCategory(params);
      setLoading(false);
      toast.success('新增類別成功。');
      history.push('/promotionCategory');
    } catch (err) {
      setLoading(false);
      toast.warning('新增類別失敗，請重試。');
    }
  };

  const updateCategory = async (values: FormItem) => {
    setLoading(true);
    try {
      const { name, nameEN, nameCN } = values;
      const params = {
        name,
        CategoryLocs: [
          {
            name: nameEN,
            locale: LocaleStr[0],
          },
          {
            name: nameCN,
            locale: LocaleStr[1],
          },
        ],
      };
      await updateOneCategory(categoryid!, params);
      setLoading(false);
      toast.success('編輯類別成功。');
    } catch (err) {
      setLoading(false);
      toast.warning('編輯類別失敗，請重試。');
      console.log(err);
    }
  };

  const onSubmit = async (values: FormItem) => {
    if (categoryid) {
      updateCategory(values);
    } else {
      createNewCategory(values);
    }
  };

  const deleteConfirm = async () => {
    setDeleteLoading(true);
    try {
      await deleteOneCategory(categoryid!);
      toast.success('成功刪除類別');
      setDeleteLoading(false);
      setDeleteVisible(false);
      history.replace('/promotionCategory');
    } catch (err) {
      toast.warning('刪除類別失敗，請重試。');
      setDeleteLoading(false);
      setDeleteVisible(false);
    }
  };

  const TITLE = `${categoryid ? '編輯' : '新增'}廣告類別`;
  const BreadcrumbsOptions = [
    { title: '廣告類別', path: '/promotionCategory' },
    { title: '廣告類別列表', path: '/promotionCategory' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs title={TITLE} breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>廣告類別資料</CardTitle>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({ values, setFieldValue, errors }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col lg="6" xs="12">
                                <InputField
                                  name="name"
                                  label="類別名稱"
                                  placeholder="輸入類別名稱"
                                  type="text"
                                />
                              </Col>
                              <Col lg="6" xs="12">
                                <InputField
                                  name="nameEN"
                                  label="Category Name (Optional)"
                                  placeholder="Input Category Name"
                                  type="text"
                                />
                              </Col>

                              <Col lg="6" xs="12">
                                <InputField
                                  name="nameCN"
                                  label="类别名称 (选填)"
                                  placeholder="输入类别名称"
                                  type="text"
                                />
                              </Col>
                            </Row>

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                              {categoryid ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible(true)}
                                >
                                  刪除
                                </Button>
                              ) : null}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={deleteVisible} scrollable={true}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              刪除類別
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setDeleteVisible(false)}
              disabled={deleteLoading}
            ></button>
          </div>
          <div className="modal-body">
            <p>{`確定要刪除類別（編號: ${categoryid}）? 該類別所有廣告亦會隨之刪除。`}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setDeleteVisible(false)}
              disabled={deleteLoading}
            >
              取消
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteConfirm}
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <i className="bx bx-loader-circle bx-spin" />
              ) : (
                '確定'
              )}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

import { Filter, ListResult } from 'app/models';
import Axios from '.';
import { TakeDown, TakeDownData } from 'app/models';

/*
 ** Submit Take Down Report (https://menspace-app-dev.appicidea.com/doc/v2#get-/v1/takeDown/-takedownId-)
 */

export const createTakeDown = async (params: TakeDown) => {
  const { data } = await Axios.post<any>(`/takedown`, params);
  return data;
};

/*
 ** Submit Take Down Report (https://menspace-app-dev.appicidea.com/doc/v2#get-/v1/takeDown/-takedownId-)
 */

export interface GetTakeDownListParams extends Filter {}

export const getTakeDownList = async (params: GetTakeDownListParams) => {
  const { data } = await Axios.get<ListResult<TakeDownData>>('/takedown', {
    params,
  });
  return data;
};

/*
 ** Get one TakeDown by takedownId (https://menspace-app-dev.appicidea.com/doc/v2#get-/v1/takeDown/-takedownId-)
 */

export const getOneTakeDown = async (takedownId: string | number) => {
  const { data } = await Axios.get<TakeDownData>(`/takedown/${takedownId}`);
  return data;
};

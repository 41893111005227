import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { toast } from 'react-toastify';
import { logout } from 'app/services/AdminServices';

interface AuthState {
  error: string | null;
  success: string | null;
  loginLoading: boolean;
  loginError: string;
}

const initialState = {
  error: '',
  success: 'ok',
  loginLoading: false,
  loginError: '',
} as AuthState;

const authSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    loginStart(state) {
      state.loginLoading = true;
      state.loginError = '';
    },
    loginSuccess(state) {
      state.loginLoading = false;
      state.loginError = '';
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    profileSuccess(state, action: PayloadAction<string>) {
      state.success = action.payload;
    },
    profileError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    resetProfileFlag(state, action: PayloadAction<string | null>) {
      state.success = null;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  profileSuccess,
  profileError,
  resetProfileFlag,
  loginStart,
  loginSuccess,
  loginFail,
} = authSlice.actions;
export default authSlice.reducer;

// dispatch actions
export const logoutUser =
  (history): AppThunk =>
  async dispatch => {
    try {
      localStorage.removeItem('authUser');
      await logout();
      history.replace('/login');
    } catch (err) {
      toast.warning('請檢查你的網絡。');
    }
  };

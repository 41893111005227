import { Filter } from 'app/models';
import Axios from './';
import { ListResult } from 'app/models';
import {
  Promotion,
  PromotionRequest,
  PromotionRequestStatus,
  Category,
  Locale,
} from 'app/models/Promotion';

/**
* * get promotion List (https://tungwah-dev.appicidea.com/doc/#/Promotion/get_v1_promotion_)
+*/

export interface GetPrmotionListParams extends Filter {
  category?: string | undefined;
  status?: string | undefined;
  isTopBanner?: boolean | undefined;
}

export const getAdsList = async (params: GetPrmotionListParams) => {
  const { data } = await Axios.get<ListResult<Promotion>>('/promotion', {
    params,
  });
  return data;
};

/**
* * create promotion (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/post_v1_promotion)
+*/

export interface CreateAdsLocsParams {
  locale?: Locale | undefined;
  coverImage?: string;
  title?: string;
  header?: string;
  images?: string[];
  poster?: string;
  content?: string;
}

export interface CreatePromotionParams {
  categoryid: number;
  coverImage?: string;
  title?: string;
  header?: string;
  images?: string[];
  poster?: string;
  content?: string;
  startDate: string | null;
  endDate: string | null;
  posterStartDate?: string;
  posterEndDate?: string;
  isTopBanner: boolean;
  remarks: string;
  phone: string;
  PromotionLocs: CreateAdsLocsParams[];
}

export const CreateAds = async (params: CreatePromotionParams) => {
  const { data } = await Axios.post<string>('/promotion', params);
  return data;
};

/**
* * get one promotion (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/get_v1_promotion__promotionid_)
+*/

export const getOneAds = async (promotionid: string | number) => {
  const { data } = await Axios.get<Promotion>(`/promotion/${promotionid}`);
  return data;
};

/**
* * get promotion category (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/get_v1_promotion_category)
+*/

export const getAdsCategoryList = async () => {
  const { data } = await Axios.get<Category[]>('/promotion/category');
  return data;
};

/**
* * get promotion request List (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/get_v1_promotion_request)
+*/

export interface GetAdsRequestParams extends Filter {
  status?: string;
}

export const getAdsRequest = async (params: GetAdsRequestParams) => {
  const { data } = await Axios.get<ListResult<PromotionRequest>>(
    '/promotion/request',
    {
      params,
    },
  );
  return data;
};

/**
* * update one promotion (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/patch_v1_promotion__promotionid_)
+*/

export const updateOneAds = async (
  promotionid: number,
  params: CreatePromotionParams,
) => {
  const { data } = await Axios.patch<string>(
    `/promotion/${promotionid}`,
    params,
  );
  return data;
};

/**
* * Delete one promotion (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/patch_v1_promotion__promotionid_)
+*/

export const deleteOneAds = async (promotionid: number) => {
  const { data } = await Axios.delete<string>(`/promotion/${promotionid}`);
  return data;
};

/**
* * get one promotion request (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/get_v1_promotion_request__promotionRequestid_)
+*/

export const getOneAdRequest = async (promotionRequestid: string) => {
  const { data } = await Axios.get<PromotionRequest>(
    `/promotion/request/${promotionRequestid}`,
  );
  return data;
};

/**
* * update promotion request (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/patch_v1_promotion_request__promotionRequestid_)
+*/

export interface UpdateAdsRequestParams {
  message: string;
  displayName: string;
  email: string;
  phone: string;
  status?: PromotionRequestStatus;
}

export const updateAdsRequest = async (
  promotionRequestid: number,
  params: UpdateAdsRequestParams,
) => {
  const { data } = await Axios.patch<string>(
    `/promotion/request/${promotionRequestid}`,
    params,
  );
  return data;
};

/**
* * Create Category (https://cmhk-wine-dev.appicidea.com/doc/#/Ads/get_v1_ads_request)
+*/

export interface CategoryParamsLocs {
  name: string;
  locale: Locale;
}

export interface CreateCategoryParams {
  name: string;
  CategoryLocs: CategoryParamsLocs[];
}

export const createCategory = async (params: CreateCategoryParams) => {
  const { data } = await Axios.post<string>(`/promotion/category`, params);
  return data;
};

/**
* * Get one category (https://cmhk-wine-dev.appicidea.com/doc/#/Ads/get_v1_ads_category__categoryid_)
+*/

export const getOneCategory = async (categoryid: string) => {
  const { data } = await Axios.get<Category>(
    `/promotion/category/${categoryid}`,
  );
  return data;
};

/**
* * Update Category (https://cmhk-wine-dev.appicidea.com/doc/#/Ads/put_v1_ads_category__categoryid_)
+*/

export const updateOneCategory = async (
  categoryid: number,
  params: CreateCategoryParams,
) => {
  const { data } = await Axios.put<string>(
    `/promotion/category/${categoryid}`,
    params,
  );
  return data;
};

/**
* * Delete Category (https://cmhk-wine-dev.appicidea.com/doc/#/Ads/delete_v1_ads_category__categoryid_)
+*/

export const deleteOneCategory = async (categoryid: number) => {
  const { data } = await Axios.delete<string>(
    `/promotion/category/${categoryid}`,
  );
  return data;
};

/**
* * Send noti in one ad (https://cmhk-wine-dev.appicidea.com/doc/#/Ads/post_v1_ads__promotionid__sendNotification)
+*/

export const sendAdsNotification = async (promotionid: number) => {
  const { data } = await Axios.post<string>(
    `/promotion/${promotionid}/sendNotification`,
  );
  return data;
};

/**
* * get promotion poster (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/get_v1_promotion_poster)
+*/

export interface GetAdsPosterParams extends Filter {
  targetDate?: string;
}

export const getAdsPoster = async (params: GetAdsPosterParams) => {
  const { data } = await Axios.get<ListResult<Promotion>>('/promotion/poster', {
    params,
  });
  return data;
};

/**
* * Delete Promotion Request (https://cmhk-wine-dev.appicidea.com/doc/#/Promotion/delete_v1_promotion_request__promotionRequestid_)
+*/

export const deleteOnePromotionRequest = async (promotionRequestid: number) => {
  const { data } = await Axios.delete<string>(
    `/promotion/request/${promotionRequestid}`,
  );
  return data;
};

import { TimeStamp } from './Common';

export const PromotionStatusStr = [
  'Pending',
  'Active',
  'PendingActive',
  'Expired',
] as const;

export type PromotionStatus = typeof PromotionStatusStr[number];

export enum PromotionStatusOptions {
  'Pending' = '等待中',
  'Active' = '啟用中',
  'PendingActive' = '等待啟用中',
  'Expired' = '已過期',
}
export const PromotionRequestStatusStr = [
  'Pending',
  'Completed',
  'Canceled',
] as const;
export type PromotionRequestStatus = typeof PromotionRequestStatusStr[number];

export enum PromotionRequestStatusOptions {
  'Pending' = '等待中',
  'Completed' = '已完成',
  'Canceled' = '已取消',
}

export interface LocaleRes extends TimeStamp {
  adsLocid: number;
  promotionid: number;
  content?: string;
  coverImage: null | string;
  header: string;
  images: string[];
  locale: Locale;
  poster: null | string;
  title: string;
}

export interface Promotion extends TimeStamp {
  PromotionLocs: LocaleRes[];
  Category: Category;
  promotionid: number;
  categoryid: number;
  content?: string;
  coverImage: string;
  endDate: string;
  header: string;
  images: string[];
  isTopBanner: boolean;
  poster: null | string;
  posterEndDate: null | string;
  posterStartDate: null | string;
  remarks: null | string;
  startDate: string;
  status: string;
  title: string;
  phone: string;
}

export const LocaleStr = ['en-US', 'zh-CN', 'zh-HK'] as const;
export type Locale = typeof LocaleStr[number];

export interface CategoryLocs extends TimeStamp {
  locale: Locale;
  name: string;
  id: number;
  categoryid: number;
}

export interface Category extends TimeStamp {
  categoryid: number;
  name: string;
  CategoryLocs: CategoryLocs[];
}

export interface PromotionRequest extends TimeStamp {
  adminRemarks: string;
  promotionRequestid: number;
  displayName: string;
  email: string;
  message: string;
  phone: string;
  rentalid: null | number;
  status: PromotionRequestStatus;
  userid: number;
}

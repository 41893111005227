import { StripeLog, Rental, TimeStamp, User } from '.';

export enum TransportStatusOptions {
  'Pending' = '待運輸',
  'Completed' = '運輸完成',
  'Canceled' = '運輸取消',
}

export type TransportStatus = keyof typeof TransportStatusOptions;

export enum TransportPaymentStatusOption {
  'Draft' = '待運輸',
  'Pending' = '待付款',
  'InProgress' = '待確認單據',
  'Completed' = '付款完成',
  'Canceled' = '待付款',
  'Failed' = '待付款',
  'AdminCanceled' = '運輸取消',
}

export type TransportPaymentStatus = keyof typeof TransportPaymentStatusOption;

export interface TransportRequest extends TimeStamp {
  Rental: Rental;
  StripeLogs: StripeLog[];
  box: number;
  cost: number;
  costRemark: string;
  district: string; // change to key
  floor: number;
  hasLift: boolean;
  paymentMethod: string;
  paymentStatus: TransportPaymentStatus;
  receiveDate: string;
  receiveEndTime: string;
  receiveStartTime: string;
  receiverName: string;
  receiverPhone: string;
  region: string; // change to key
  remarks: string;
  rentalid: null | number;
  room: null | string;
  streetBuilding: string;
  transportRequestid: number;
  transportStatus: TransportStatus;
  type: string; // change to key
  userid: number;
  images?: string[];
  receiptImages?: string[];
  clientRemarks?: string;
  User: User;
  transactionFee: number | null;
}

export enum TransportPaymentMethod {
  'wallet' = '錢包',
  'stripe' = '信用卡',
  'wechatpay' = '微信支付',
  'alipay' = '支付寶',
  'bankTransfer' = '銀行轉帳',
}

export type TransportPaymentMethodType = keyof typeof TransportPaymentMethod;

/**
 * district code reference (https://www.ogcio.gov.hk/en/our_work/infrastructure/e_government/if/code_lists/district_council_code_eng/index.html)
 * HK:
 * 中西區 Central and Western District - CW
 * 東區 Eastern District - EST
 * 南區 Southern District - STH
 * 灣仔區 Wan Chai District - WC
 *
 * KL:
 * 九龍城區 Kowloon City District - KLC
 * 觀塘區 Kwun Tong District - KT
 * 深水埗區 Sham Shui Po District - SSP
 * 黃大仙區 Wong Tai Sin District - WTS
 * 油尖旺區 Yau Tsim Mong District - YTM
 *
 * NT:
 * 離島區 Islands District - ILD
 * 葵青區 Kwai Tsing District - KC
 * 北區 North District - NTH
 * 西貢區 Sai Kung District - SK
 * 沙田區 Sha Tin District - ST
 * 大埔區 Tai Po District - TP
 * 荃灣區 Tsuen Wan District - TW
 * 屯門區 Tuen Mun District - TM
 * 元朗區 Yuen Long District - YL
 */

export type RegionType = typeof REGION[number];
export type HKType = typeof HK_DISTRICT[number];
export type KLType = typeof KL_DISTRICT[number];
export type NTType = typeof NT_DISTRICT[number];

export const REGION = ['HK', 'KL', 'NT'];

export const HK_DISTRICT = ['CW', 'EST', 'STH', 'WC'];

export const KL_DISTRICT = ['KLC', 'KT', 'SSP', 'WTS', 'YTM'];

export const NT_DISTRICT = [
  'ILD',
  'KC',
  'NTH',
  'SK',
  'ST',
  'TP',
  'TW',
  'TM',
  'YL',
];

/**
 * 住宅 residential
 * 商廈 commercial
 * 酒店 hotel
 * 會所 clubhouse
 * 超市 supermarket
 */

export type AddressType = typeof ADDRESS_TYPE[number];

export const ADDRESS_TYPE = [
  'residential',
  'commercial',
  'hotel',
  'clubhouse',
  'supermarket',
];

export enum ADDRESS_BUILDING_TYPE {
  'residential' = '住宅',
  'commercial' = '商廈',
  'hotel' = '酒店',
  'clubhouse' = '會所',
  'supermarket' = '超市',
}

export type LiftType = typeof LIFT_TYPE[number];
export const LIFT_TYPE = ['withLift', 'withoutLift'];

export enum REGION_TYPE {
  'HK' = '香港',
  'KL' = '九龍',
  'NT' = '新界',
}

export enum HK_DISTRICT_TYPE {
  'CW' = '中西區',
  'EST' = '東區',
  'STH' = '南區',
  'WC' = '灣仔',
}

export enum KL_DISTRICT_TYPE {
  'KLC' = '九龍城',
  'KT' = '觀塘',
  'SSP' = '深水埗',
  'WTS' = '黃大仙',
  'YTM' = '油尖旺',
}

export enum NT_DISTRICT_TYPE {
  'ILD' = '離島',
  'KC' = '葵青',
  'NTH' = '北區',
  'SK' = '西貢',
  'ST' = '沙田',
  'TP' = '大埔',
  'TW' = '荃灣',
  'TM' = '屯門',
  'YL' = '元朗',
}

export interface Cost {
  cost: number;
  transactionFee: number;
}

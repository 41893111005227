/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink, useLocation } from 'react-router-dom';
import { getAdsRequest } from 'app/services/PromotionService';
import { getTransportRequestList } from 'app/services/TransportService';
import { Filter } from 'app/models';
import { getPackageReceiveRegistrationlist } from 'app/services/PickupServices';
import {
  setLocalStorage,
  getLocalStorage,
  ValuePair,
} from 'app/helpers/LocalStorageHelper';

const SidebarContent = () => {
  const ref = useRef<any>();
  const location = useLocation();
  const [promotion, setPromotion] = useState<ValuePair | null>(null);

  const [transport, setTransport] = useState<ValuePair | null>(null);

  const [pickup, setPickup] = useState<ValuePair | null>(null);

  useEffect(() => {
    const interval = setInterval(async () => {
      const filter: Filter = { limit: 1, offset: 0 };
      try {
        const adRes = await getAdsRequest(filter);
        const transRes = await getTransportRequestList(filter);
        const pickupRes = await getPackageReceiveRegistrationlist(filter);

        setData(adRes, transRes, pickupRes);
      } catch (err) {}
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const setData = (adRes, transRes, pickupRes) => {
    const promtionData: ValuePair | null = getLocalStorage(
      'cmhk-admin-promotion',
    );
    const transportData: ValuePair | null = getLocalStorage(
      'cmhk-admin-transport',
    );
    const pickupData: ValuePair | null = getLocalStorage('cmhk-admin-pickup');
    if (adRes && adRes.rows.length > 0) {
      const { promotionRequestid, updatedAt } = adRes.rows[0];
      if (!promtionData) {
        setLocalStorage('cmhk-admin-promotion', {
          prev: {
            id: promotionRequestid,
            updateAt: updatedAt,
          },
          current: null,
        });
        setPromotion({
          prev: {
            id: promotionRequestid,
            updateAt: updatedAt,
          },
          current: null,
        });
      } else if (!promtionData.prev) {
        setLocalStorage('cmhk-admin-promotion', {
          prev: {
            id: promotionRequestid,
            updateAt: updatedAt,
          },
          current: null,
        });
        setPromotion({
          prev: {
            id: promotionRequestid,
            updateAt: updatedAt,
          },
          current: null,
        });
      } else if (promtionData.prev?.id !== promotionRequestid) {
        setLocalStorage('cmhk-admin-promotion', {
          ...promtionData,
          current: {
            id: promotionRequestid,
            updateAt: updatedAt,
          },
        });
        setPromotion({
          ...promtionData,
          current: {
            id: promotionRequestid,
            updateAt: updatedAt,
          },
        });
      } else if (
        promtionData.prev?.id === promotionRequestid &&
        promtionData.prev?.updateAt !== updatedAt
      ) {
        setLocalStorage('cmhk-admin-promotion', {
          ...promtionData,
          current: {
            id: promotionRequestid,
            updateAt: updatedAt,
          },
        });
        setPromotion({
          ...promtionData,
          current: {
            id: promotionRequestid,
            updateAt: updatedAt,
          },
        });
      }
    }

    if (transRes && transRes.rows.length > 0) {
      const { transportRequestid, updatedAt } = transRes.rows[0];
      if (!transportData) {
        setLocalStorage('cmhk-admin-transport', {
          prev: {
            id: transportRequestid,
            updateAt: updatedAt,
          },
          current: null,
        });
        setTransport({
          prev: {
            id: transportRequestid,
            updateAt: updatedAt,
          },
          current: null,
        });
      } else if (!transportData.prev) {
        setLocalStorage('cmhk-admin-transport', {
          prev: {
            id: transportRequestid,
            updateAt: updatedAt,
          },
          current: null,
        });
        setTransport({
          prev: {
            id: transportRequestid,
            updateAt: updatedAt,
          },
          current: null,
        });
      } else if (transportData.prev?.id !== transportRequestid) {
        setLocalStorage('cmhk-admin-transport', {
          ...transportData,
          current: {
            id: transportRequestid,
            updateAt: updatedAt,
          },
        });
        setTransport({
          ...transportData,
          current: {
            id: transportRequestid,
            updateAt: updatedAt,
          },
        });
      } else if (
        transportData.prev?.id === transportRequestid &&
        transportData.prev?.updateAt !== updatedAt
      ) {
        setLocalStorage('cmhk-admin-transport', {
          ...transportData,
          current: {
            id: transportRequestid,
            updateAt: updatedAt,
          },
        });
        setTransport({
          ...transportData,
          current: {
            id: transportRequestid,
            updateAt: updatedAt,
          },
        });
      }
    }

    if (pickupRes && pickupRes.rows.length > 0) {
      const { packageReceiveRegistrationid, updatedAt } = pickupRes.rows[0];
      if (!pickupData) {
        setLocalStorage('cmhk-admin-pickup', {
          prev: {
            id: packageReceiveRegistrationid,
            updateAt: updatedAt,
          },
          current: null,
        });
        setPickup({
          prev: {
            id: packageReceiveRegistrationid,
            updateAt: updatedAt,
          },
          current: null,
        });
      } else if (!pickupData.prev) {
        setLocalStorage('cmhk-admin-pickup', {
          prev: {
            id: packageReceiveRegistrationid,
            updateAt: updatedAt,
          },
          current: null,
        });
        setPickup({
          prev: {
            id: packageReceiveRegistrationid,
            updateAt: updatedAt,
          },
          current: null,
        });
      } else if (pickupData.prev?.id !== packageReceiveRegistrationid) {
        setLocalStorage('cmhk-admin-pickup', {
          ...pickupData,
          current: {
            id: packageReceiveRegistrationid,
            updateAt: updatedAt,
          },
        });
        setPickup({
          ...pickupData,
          current: {
            id: packageReceiveRegistrationid,
            updateAt: updatedAt,
          },
        });
      } else if (
        pickupData.prev?.id === packageReceiveRegistrationid &&
        pickupData.prev?.updateAt !== updatedAt
      ) {
        setLocalStorage('cmhk-admin-pickup', {
          ...pickupData,
          current: {
            id: packageReceiveRegistrationid,
            updateAt: updatedAt,
          },
        });
        setPickup({
          ...pickupData,
          current: {
            id: packageReceiveRegistrationid,
            updateAt: updatedAt,
          },
        });
      }
    }
  };

  const resetPromotion = () => {
    const promtionData: ValuePair | null = getLocalStorage(
      'cmhk-admin-promotion',
    );
    setLocalStorage('cmhk-admin-promotion', {
      prev: promtionData && promtionData.current,
      current: null,
    });
    setPromotion(null);
  };

  const resetTransport = () => {
    const transportData: ValuePair | null = getLocalStorage(
      'cmhk-admin-transport',
    );
    setLocalStorage('cmhk-admin-transport', {
      prev: transportData && transportData.current,
      current: null,
    });
    setTransport(null);
  };

  const resetPickup = () => {
    const pickupData: ValuePair | null = getLocalStorage('cmhk-admin-pickup');
    setLocalStorage('cmhk-admin-pickup', {
      prev: pickupData && pickupData.current,
      current: null,
    });
    setPickup(null);
  };

  const renderSubMenuClass = path => {
    const pathName = location.pathname;
    if (pathName.includes(path)) {
      return 'mm-show';
    } else {
      return 'mm-collapse';
    }
  };

  useEffect(() => {
    const promotionItem = getLocalStorage('cmhk-admin-promotion');
    const transportItem = getLocalStorage('cmhk-admin-transport');
    const pickupItem = getLocalStorage('cmhk-admin-pickup');
    setPickup(pickupItem);
    setPromotion(promotionItem);
    setTransport(transportItem);

    async function getDataWhenInit() {
      const filter: Filter = { limit: 1, offset: 0 };
      try {
        const adRes = await getAdsRequest(filter);
        const transRes = await getTransportRequestList(filter);
        const pickupRes = await getPackageReceiveRegistrationlist(filter);

        setData(adRes, transRes, pickupRes);
      } catch (err) {}
    }

    getDataWhenInit();
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">功能列表</li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/promotion">
                <i className="bx bx-conversation"></i>
                <span>廣告管理</span>
                {promotion !== null && promotion.current ? (
                  <span className="translate-middle badge border border-light rounded-circle bg-danger p-1">
                    <span className="visually-hidden">unread messages</span>
                  </span>
                ) : null}
              </NavLink>
              <ul
                className={`sub-menu ${renderSubMenuClass('promotion')}`}
                aria-expanded="false"
              >
                <li>
                  <NavLink to="/promotion" activeClassName="active mm-active">
                    廣告列表
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/promotionRequest"
                    activeClassName="active mm-active"
                    onClick={() => resetPromotion()}
                  >
                    廣告申請{' '}
                    {promotion !== null && promotion.current ? (
                      <span className="translate-middle badge border border-light rounded-circle bg-danger p-1">
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    ) : null}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/promotionCategory"
                    activeClassName="active mm-active"
                  >
                    廣告類別
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink
                to="/transportRequest"
                className="has-arrow waves-effect"
              >
                <i className="bx bxs-truck"></i>
                <span>運輸管理</span>
                {transport && transport.current ? (
                  <span className="translate-middle badge border border-light rounded-circle bg-danger p-1">
                    <span className="visually-hidden">unread messages</span>
                  </span>
                ) : null}
              </NavLink>
              <ul
                className={`sub-menu ${renderSubMenuClass('transport')}`}
                aria-expanded="false"
              >
                <li>
                  <NavLink
                    to="/transportRequest"
                    activeClassName="active mm-active"
                    onClick={() => resetTransport()}
                  >
                    運輸申請
                    {transport && transport.current ? (
                      <span className="translate-middle badge border border-light rounded-circle bg-danger p-1">
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    ) : null}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/transportRecord"
                    activeClassName="active mm-active"
                  >
                    詳細付款紀錄
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink
                to="/pickupRequest"
                className="waves-effect"
                activeClassName="active mm-active"
                onClick={() => resetPickup()}
              >
                <i className="bx bx-package"></i>
                <span>收貨申請</span>
                {pickup && pickup.current ? (
                  <span className="translate-middle badge border border-light rounded-circle bg-danger p-1">
                    <span className="visually-hidden">unread messages</span>
                  </span>
                ) : null}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/floorplan"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-store"></i>
                <span>酒窖儲藏室管理</span>
              </NavLink>
            </li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/rental">
                <i className="bx bx-detail"></i>
                <span>租約管理</span>
              </NavLink>
              <ul
                className={`sub-menu ${renderSubMenuClass('rental')}`}
                aria-expanded="false"
              >
                <li>
                  <NavLink to="/rental" activeClassName="active mm-active">
                    租約
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/rentalHistory"
                    activeClassName="active mm-active"
                  >
                    租約繳費管理
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink
                to="/admins"
                className="waves-effect"
                activeClassName="active mm-active"
                exact
              >
                <i className="bx bx-user-circle"></i>
                <span>管理員</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/users"
                className="waves-effect"
                activeClassName="active mm-active"
                exact
              >
                <i className="bx bx-user"></i>
                <span>會員管理</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/notification"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bxs-send"></i>
                <span>推送訊息</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/take-down"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-archive-arrow-down"></i>
                <span>移除申請</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;

import { Filter, PackageReceive } from 'app/models';
import Axios from '.';
import { ListResult } from 'app/models';

/**
* * get Package Receive Registration list (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/get_v1_transport_packageReceiveRegistration)
+*/

export interface GetPackageReceiveRegistrationParams extends Filter {
  displayName?: string;
  transportStatus?: string;
  paymentStatus?: string;
  packageReceiveRegistrationid?: string;
  userid?: string | number;
}

export const getPackageReceiveRegistrationlist = async (
  params: GetPackageReceiveRegistrationParams,
) => {
  const { data } = await Axios.get<ListResult<PackageReceive>>(
    '/transport/packageReceiveRegistration/admin',
    { params },
  );
  return data;
};

/**
* * Create Package receive (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/post_v1_transport_packageReceiveRegistration)
+*/

export interface CreatePackageReceiveParams {
  paymentStatus?: string | null;
  transportStatus?: string;
  cost: number | null;
  displayName: string;
  lockerNumber: string;
  pickupDate: string | null;
  box: number | null;
  // pickupDate: string;
  paymentMethod: string | null;
  remarks: string;
  clientRemarks?: string;
  passcode: string;
  userid: number;
  expectedDeliveryDate: string;
}

export const createPackageReceive = async (
  params: CreatePackageReceiveParams,
) => {
  const { data } = await Axios.post<string>(
    `/transport/packageReceiveRegistration`,
    params,
  );
  return data;
};

/**
* * Get one package receive (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/get_v1_transport_packageReceiveRegistration__packageReceiveRegistrationid_
+*/

export const getOnePackageReceive = async (
  packageReceiveRegistrationid: string,
) => {
  const { data } = await Axios.get<PackageReceive>(
    `/transport/packageReceiveRegistration/${packageReceiveRegistrationid}`,
  );
  return data;
};

/**
* * update Package receive (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/patch_v1_transport_packageReceiveRegistration__packageReceiveRegistrationid_)
+*/

export interface UpdatePackageReceiveParams {
  displayName?: string;
  lockerNumber?: string;
  pickupDate?: string | null;
  box?: number | null;
  expectedDeliveryDate?: string | null;
  images?: string[];
  paymentMethod?: string | null;
  remarks?: string;
  rentalid?: number;
  passcode?: string;
  paymentStatus?: string | null;
  transportStatus?: string;
  cost?: number | null;
}

export const updatePackageReceive = async (
  packageReceiveRegistrationid: number,
  params: UpdatePackageReceiveParams,
) => {
  const { data } = await Axios.patch<string>(
    `/transport/packageReceiveRegistration/${packageReceiveRegistrationid}`,
    params,
  );
  return data;
};

/**
* * upload Receipt to package receive (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/patch_v1_transport_packageReceiveRegistration__packageReceiveRegistrationid_)
+*/

export const uploadReceiptToPackageReceive = async (
  packageReceiveRegistrationid: number,
  params: {
    images: string[];
  },
) => {
  const { data } = await Axios.patch<string>(
    `/transport/packageReceiveRegistration/${packageReceiveRegistrationid}`,
    params,
  );
  return data;
};

/**
* * delete Package Receive (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/delete_v1_transport_packageReceiveRegistration__packageReceiveRegistrationid_)
+*/

export const deletePackageReceive = async (
  packageReceiveRegistrationid: number,
) => {
  const { data } = await Axios.delete<string>(
    `/transport/packageReceiveRegistration/${packageReceiveRegistrationid}`,
  );
  return data;
};

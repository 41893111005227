import {
  AddressType,
  Cost,
  Filter,
  HKType,
  KLType,
  NTType,
  RegionType,
  TransportRequest,
} from 'app/models';
import Axios from '.';
import { ListResult } from 'app/models';

/**
* * get transport request List (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/get_v1_transport_admin)
+*/

export interface GetTransportRequestListParams extends Filter {
  transportStatus?: string;
  paymentStatus?: string;
  receiveDate?: string;
  transportRequestid?: string;
  userid?: string | number;
}

export const getTransportRequestList = async (
  params: GetTransportRequestListParams,
) => {
  const { data } = await Axios.get<ListResult<TransportRequest>>(
    '/transport/request/admin',
    {
      params,
    },
  );
  return data;
};

/**
* * create transport request (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/post_v1_transport_request)
+*/

export interface CreateTransportRequestParams {
  box: number;
  costRemark?: string;
  receiverName: string;
  receiverPhone: string;
  receiveDate: string;
  receiveStartTime: string;
  receiveEndTime: string;
  region: RegionType;
  district: HKType | KLType | NTType;
  type: AddressType;
  streetBuilding: string;
  floor: number;
  room: string;
  hasLift: boolean;
  paymentMethod: string | null;
  remarks: string;
  userid: number;
  // clientRemarks?: string;
  // paymentStatus?: string;
  // transportStatus?: string;
  // cost?: number | null;
}

export const createTransportRequest = async (
  params: CreateTransportRequestParams,
) => {
  const { data } = await Axios.post<any>('/transport/request', params);
  return data;
};

/**
* * update transport request (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/patch_v1_transport_request__transportRequestid_)
+*/

export interface UpdateTransportRequestParams {
  box?: number;
  receiverName?: string;
  receiverPhone?: string;
  receiveDate?: string;
  receiveStartTime?: string;
  receiveEndTime?: string;
  region?: RegionType;
  district?: HKType | KLType | NTType;
  type?: AddressType;
  streetBuilding?: string;
  floor?: number;
  room?: string;
  hasLift?: boolean;
  paymentMethod?: string | null;
  remarks?: string;
  clientRemarks?: string;
  paymentStatus?: string;
  transportStatus?: string;
  cost?: number | null;
  costRemark?: string;
}

export const updateTransportRequest = async (
  transportRequestid: number,
  params: UpdateTransportRequestParams,
) => {
  const { data } = await Axios.patch<any>(
    `/transport/request/${transportRequestid}`,
    params,
  );
  return data;
};

/**
* * get one transport request (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/get_v1_transport_request)
+*/

export const getOneTransportRequest = async (transportRequestid: string) => {
  const { data } = await Axios.get<TransportRequest>(
    `/transport/request/${transportRequestid}`,
  );
  return data;
};

/**
* * delete transport request (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/delete_v1_transport_request__transportRequestid_)
+*/

export const deleteTransportRequest = async (transportRequestid: number) => {
  const { data } = await Axios.delete<string>(
    `/transport/request/${transportRequestid}`,
  );
  return data;
};

/**
* * upload Receipt to transport request (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/patch_v1_transport_request__transportRequestid_)
+*/

export const uploadReceiptToTransportRequest = async (
  transportRequestid: number,
  params: {
    images: string[];
  },
) => {
  const { data } = await Axios.patch<string>(
    `/transport/request/${transportRequestid}`,
    params,
  );
  return data;
};

/**
* * calculate cost (https://cmhk-wine-dev.appicidea.com/doc/#/Transport/post_v1_transport_calculateCost)
+*/

export interface CalculateCostParams {
  box: number;
  receiveDate: string;
  receiveStartTime: string;
  receiveEndTime: string;
  region: string;
  district: string;
  type: string;
  floor: number;
  hasLift: boolean;
}

export const CalculateCost = async (params: CalculateCostParams) => {
  const { data } = await Axios.post<Cost>('/transport/calculateCost', params);
  return data;
};

import { RentItems } from 'app/services/RentalService';
import { TimeStamp, StripeLog } from './';
import { Room } from './Room';
import { User } from './User';

export const RentalPaymentTermItems = ['Monthly', 'Yearly'] as const;
export type RentalPaymentTerm = typeof RentalPaymentTermItems[number];

export interface RoomRental extends TimeStamp {
  Room: Room;
  rentalid: number;
  roomRentalid: number;
  roomid: number;
}

export interface RentalBase {
  customerName: string;
  paymentTerm: RentalPaymentTerm;
  rentItems: rentItems[];
  rentalAmount: number;
  rentalEndDate: string;
  rentalStartDate: string;
  rentalStatus: RentalStatusType;
  rentalid: number;
  userid: number;
  RoomRentals: RoomRental[];
}

export interface Rental extends TimeStamp, RentalBase {
  User: User;
}

export interface OneRental extends TimeStamp, RentalBase {
  User: User;
}

export type RentalStatusType = 'Expired' | 'Future' | 'Ongoing' | 'Default';

export interface rentItems {
  type: string;
  amount: number;
  totalPrice: number;
}

export enum PaymentTermText {
  'Monthly' = '月費',
  'Yearly' = '年費',
}

export type RentalPaymentTermType = keyof typeof PaymentTermText;

export interface RentalHistory extends TimeStamp {
  StripeLogs: StripeLog[];
  rentalHistoryid: number;
  rentalid: number;
  customerName: string;
  rentalStartDate: string;
  rentalEndDate: string;
  rentalAmount: number;
  paymentTerm: RentalPaymentTerm;
  paymentStatus: RentalPaymentStatus;
  paymentMethod: RentalPaymentMethod | null;
  receiptImages: string[];
  rentItems: RentItems[];
  stripeReference: null;
  remarks: null;
  Rental: Rental;
}

export const RentalPaymentMethodStr = [
  'wallet',
  'stripe',
  'wechatpay',
  'alipay',
  'bankTransfer',
] as const;
export type RentalPaymentMethod = typeof RentalPaymentMethodStr[number];

export enum RentalPaymentMethodOptions {
  'wallet' = '錢包',
  'stripe' = '信用卡',
  'wechatpay' = '微信支付',
  'alipay' = '支付寶',
  'bankTransfer' = '銀行轉賬',
  'cash' = '現金',
  'cheque' = '支票',
  'others' = '其他',
}

export enum RentalPaymentMethodFormOptions {
  'cash' = '現金',
  'cheque' = '支票',
  'others' = '其他',
}

export enum RentalPaymentStatusOption {
  'Pending' = '待付款',
  'InProgress' = '待確認單據',
  'Completed' = '付款完成',
  'Canceled' = '待付款',
  'Failed' = '待付款',
  'AdminCanceled' = '運輸取消',
}

export type RentalPaymentStatus = keyof typeof RentalPaymentStatusOption;

export const SEARCH_PAYMENT_STATUS_OPTIONS = [
  { value: '', label: '所有狀態' },
  { value: 'Pending,Canceled,Failed', label: '待付款' },
  { value: 'InProgress', label: '待確認單據' },
  { value: 'Completed', label: '付款完成' },
  { value: 'AdminCanceled', label: '運輸取消' },
];

export enum RentalStatus {
  'Expired' = '已完約',
  'Future' = '未生效',
  'Ongoing' = '生效中',
}

export enum PaymentMethodOptions {
  'wallet' = '錢包',
  'stripe' = '信用卡',
  'wechatpay' = '微信支付',
  'alipay' = '支付寶',
  'bankTransfer' = '銀行轉賬',
}

export type PaymentMethodType = keyof typeof PaymentMethodOptions;

export enum PaymentTransactionFee {
  'wallet' = '錢包免手續費',
  'stripe' = '手續費 3.4% + HK$2.6',
  'wechatpay' = '手續費 0.8%',
  'alipay' = '手續費 0.9%',
  'bankTransfer' = '銀行轉賬免手續費',
}

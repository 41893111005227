import { Filter, User } from 'app/models';
import Axios from './';
import { ListResult } from 'app/models';
import {
  Rental,
  RentalHistory,
  RentalPaymentMethod,
  RentalPaymentTerm,
  RentalPaymentTermType,
  OneRental,
} from 'app/models/Rental';

/**
* * get rental List (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/get_v1_rental)
+*/

export interface GetRentalListParams extends Filter {
  customerName?: string | undefined;
  cellName?: string | undefined;
  paymentTerm?: string | undefined;
  rentalEndDate_Start?: string | undefined;
  rentalEndDate_End?: string | undefined;
  rentalStatus?: string | undefined;
  roomid?: string | undefined;
  rentalid?: string | undefined;
  userid?: string | undefined;
}

export const getRentalList = async (params: GetRentalListParams) => {
  const { data } = await Axios.get<ListResult<any>>('/rental', {
    params,
  });
  return data;
};

/**
* * create a new rental (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/post_v1_rental)
+*/

export interface RentItems {
  type: string;
  amount: number | null;
  totalPrice: number | null;
}

export interface CreateNewRentalParmas {
  customerName: string;
  rentalStartDate: string | null;
  rentalEndDate: string | null;
  rentalAmount: number | null;
  paymentTerm: RentalPaymentTermType;
  rentItems: RentItems[];
  roomids: number[];
  userid: number;
}

export const createNewRental = async (params: CreateNewRentalParmas) => {
  const { data } = await Axios.post<Rental>('/rental', params);
  return data;
};

/**
 * Get Rental history list (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/get_v1_rental_history)
 */

export interface GetAllRentalHistoryParams extends Filter {
  rentalid?: string;
  customerName?: string;
  paymentStatus?: string;
  rentalStartDate_Start?: string;
  rentalStartDate_End?: string;
}

export const getAllRentalHistory = async (
  params: GetAllRentalHistoryParams,
) => {
  const { data } = await Axios.get<ListResult<RentalHistory>>(
    '/rental/history',
    { params },
  );
  return data;
};

/**
* * get one rental (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/get_v1_rental__rentalid_)
+*/

export const getOneRental = async (rentalid: string) => {
  const { data } = await Axios.get<OneRental>(`/rental/${rentalid}`);
  return data;
};

/**
* * delete one rental (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/delete_v1_rental__rentalid_)
+*/

export const deleteOneRental = async (rentalid: number) => {
  const { data } = await Axios.delete<any>(`/rental/${rentalid}`);
  return data;
};

/**
* * update one rental (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/patch_v1_rental__rentalid_)
+*/

export const updateOneRental = async (
  rentalid: number,
  params: CreateNewRentalParmas,
) => {
  const { data } = await Axios.patch<any>(`/rental/${rentalid}`, params);
  return data;
};

/**
* * get users inside rental (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/get_v1_rental__rentalid__users)
+*/

export interface GetUserInsideRental extends Filter {}

export const getUserInsideRental = async (
  rentalid: number,
  params: GetUserInsideRental,
) => {
  const { data } = await Axios.get<ListResult<User>>(
    `/rental/${rentalid}/users`,
    {
      params,
    },
  );
  return data;
};

/**
* * delete users inside rental (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/delete_v1_rental__rentalid__users)
+*/

export interface GetUserInsideRental extends Filter {}

export const deleteUserInsideRental = async (
  rentalid: number,
  userids: number,
) => {
  const { data } = await Axios.delete<any>(`/rental/${rentalid}/users`, {
    params: {
      userids,
    },
  });
  return data;
};

/**
* * add users to rental (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/post_v1_rental__rentalid__users)
+*/

export interface AddUserToRentalParams {
  userids: number[];
}

export const addUserToRental = async (
  rentalid: number,
  params: AddUserToRentalParams,
) => {
  const { data } = await Axios.post<any>(`/rental/${rentalid}/users`, params);
  return data;
};

/**
* * get rental history list (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/get_v1_rental__rentalid__history)
+*/

export interface GetRentalHistoryListParams extends Filter {}

export const getRentalHistoryList = async (
  rentalid: string,
  params: GetRentalHistoryListParams,
) => {
  const { data } = await Axios.get<any>(`/rental/${rentalid}/history`, {
    params,
  });
  return data;
};

/**
* * create new rental history (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/post_v1_rental__rentalid__history)
+*/

export interface CreateRentalHistoryParams {
  customerName: string;
  rentalStartDate: string;
  rentalEndDate: string;
  rentalAmount: number;
  paymentTerm: RentalPaymentTerm;
  paymentStatus: string;
  paymentMethod: RentalPaymentMethod | null;
  receiptImages: string[];
  rentItems: RentItems[];
}

export const createRentalHistory = async (
  rentalid: number,
  params: CreateRentalHistoryParams,
) => {
  const { data } = await Axios.post<any>(`/rental/${rentalid}/history`, params);
  return data;
};

/**
* * get one rental history (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/get_v1_rental__rentalid__history__rentalHistoryid_)
+*/

export const getOneRentalHistory = async (
  rentalid: string,
  rentalHistoryid: string,
) => {
  const { data } = await Axios.get<RentalHistory>(
    `/rental/${rentalid}/history/${rentalHistoryid}`,
  );
  return data;
};

/**
* * Update rental history (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/patch_v1_rental__rentalid__history__rentalHistoryid_)
+*/

export interface UpdateRentalHistoryParams {
  customerName?: string;
  rentalStartDate?: string;
  rentalEndDate?: string;
  rentalAmount?: number;
  paymentTerm?: RentalPaymentTerm;
  paymentStatus?: string;
  paymentMethod?: RentalPaymentMethod | null;
  receiptImages?: string[];
  rentItems?: RentItems[];
}

export const updateRentalHistory = async (
  rentalid: number,
  rentalHistoryid: number,
  params: UpdateRentalHistoryParams,
) => {
  const { data } = await Axios.patch<any>(
    `/rental/${rentalid}/history/${rentalHistoryid}`,
    params,
  );
  return data;
};

/**
* * delete rental history (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/patch_v1_rental__rentalid__history__rentalHistoryid_)
+*/

export const deleteRentalHistory = async (
  rentalid: number,
  rentalHistoryid: number,
) => {
  const { data } = await Axios.delete<any>(
    `/rental/${rentalid}/history/${rentalHistoryid}`,
  );
  return data;
};

/**
* * creat wallet history (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/post_v1_rental__rentalid__walletHistory)
+*/

export interface CreateWalletHistoryParams {
  walletBalanceChanges: number;
}

export const createWalletHistory = async (
  rentalid: number,
  params: CreateWalletHistoryParams,
) => {
  const { data } = await Axios.post<string>(
    `/rental/${rentalid}/walletHistory`,
    params,
  );
  return data;
};

/**
* * update wallet history (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/patch_v1_rental__rentalid__walletHistory__walletHistoryid_)
+*/

export const updateWalletHistory = async (
  rentalid: number,
  walletHistoryid: number,
  params: { walletBalanceChanges: number },
) => {
  const { data } = await Axios.patch<string>(
    `/rental/${rentalid}/walletHistory/${walletHistoryid}`,
    params,
  );
  return data;
};

// /**
//  * Get one walletHistory (https://cmhk-wine-dev.appicidea.com/doc/#/Rental/get_v1_rental__rentalid__walletHistory__walletHistoryid_)
//  */

// export const getOneWalletHistory = async (
//   rentalid: number,
//   walletHistoryid: number,
// ) => {
//   const { data } = await Axios.get<WalletRecord>(
//     `/rental/${rentalid}/walletHistory/${walletHistoryid}`,
//   );
//   return data;
// };

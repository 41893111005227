import React, { useEffect } from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import Switch from 'react-switch';

const Offsymbol = ({ loading }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {loading ? <i className="bx bx-loader-circle bx-spin" /> : '否'}
    </div>
  );
};

const OnSymbol = ({ loading }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {loading ? <i className="bx bx-loader-circle bx-spin" /> : '是'}
    </div>
  );
};

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  switchOnChange?: (bool: boolean) => void;
  loading?: boolean;
  watch?: () => void;
  disabled?: boolean;
}

const SwitchField = (props: OtherProps & FieldHookConfig<boolean>) => {
  const [field, meta, helpers] = useField(props);
  const { label, horizontal, switchOnChange, loading, watch, disabled } = props;
  useEffect(() => {
    if (watch) {
      watch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={
        horizontal
          ? { display: 'flex', flexDirection: 'row', alignItems: 'center' }
          : {}
      }
    >
      {label ? (
        <div
          style={{
            margin: '8px 8px 8px 0px',
            fontFamily: 'Georgia,Times,Times New Roman,serif',
          }}
        >
          {label}
        </div>
      ) : null}
      <div className="square-switch">
        <Switch
          uncheckedIcon={<Offsymbol loading={loading ? true : false} />}
          checkedIcon={<OnSymbol loading={loading ? true : false} />}
          onColor="#f8b425"
          onChange={e => {
            if (switchOnChange) {
              switchOnChange(e);
            } else {
              helpers.setValue(e);
            }
          }}
          checked={field.value}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SwitchField;

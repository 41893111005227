import { TimeStamp, StripeLog, User } from '.';

export interface PackageReceive extends TimeStamp {
  packageReceiveRegistrationid: number;
  userid: number;
  box: number;
  displayName: string;
  lockerNumber: string;
  pickupDate: string;
  expectedDeliveryDate: string;
  cost: number;
  passcode: null | string;
  images?: string[] | null;
  transactionFee: number;
  paymentMethod: string;
  transportStatus: PickupTransportStatusType;
  paymentStatus: PickupPaymentStatusType;
  remarks: string;
  clientRemarks: string;
  StripeLogs: StripeLog[];
  receiptImages: string[] | null;
  stripeReference: null | string;
  paymentDate: null | string;
  User: User;
}

export enum PickupTransportStatus {
  'Pending' = '待處理',
  'Completed' = '運輸完成',
  'Canceled' = '運輸取消',
}

export type PickupTransportStatusType = keyof typeof PickupTransportStatus;

export enum PickupPaymentStatus {
  'Drafted' = '待收貨',
  'Pending' = '待付款',
  'InProgress' = '待確認單據',
  'Completed' = '待客戶取貨',
  'Canceled' = '付款取消',
  'Failed' = '失敗',
  'AdminCompleted' = '客戶已取貨',
  'AdminCanceled' = '收貨取消',
}

export type PickupPaymentStatusType = keyof typeof PickupPaymentStatus;

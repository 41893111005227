import {
  TransportRequest,
  HK_DISTRICT_TYPE,
  KL_DISTRICT_TYPE,
  NT_DISTRICT_TYPE,
  REGION_TYPE,
  REGION,
  TransportStatus,
  PickupPaymentStatusType,
  ADDRESS_BUILDING_TYPE,
  TransportPaymentStatus,
  PickupTransportStatusType,
} from 'app/models';
import { Badge } from 'reactstrap';
import { convertToClientPrice, formatPrice } from 'app/helpers/CommonHelper';

export const formatAddress = (TRequest: TransportRequest) => {
  if (TRequest) {
    const { region, district, streetBuilding, floor, room, hasLift, type } =
      TRequest;
    let address = `${REGION_TYPE[region]} ${getDistrict(
      region,
      district,
    )} ${streetBuilding} ${floor}/F ${room || ''} | ${
      ADDRESS_BUILDING_TYPE[type]
    } ${hasLift ? '| 有升降機' : '| 沒有升降機'}`;
    return address;
  }
  return '-';
};

const getDistrict = (region, district) => {
  switch (region) {
    case REGION[0]:
      return HK_DISTRICT_TYPE[district];
    case REGION[1]:
      return KL_DISTRICT_TYPE[district];
    case REGION[2]:
      return NT_DISTRICT_TYPE[district];
    default:
      return district;
  }
};

export const getDistrictOption = region => {
  switch (region) {
    case 'HK':
      return Object.keys(HK_DISTRICT_TYPE).map(key => {
        return { value: key, label: HK_DISTRICT_TYPE[key] };
      });
    case 'KL':
      return Object.keys(KL_DISTRICT_TYPE).map(key => {
        return { value: key, label: KL_DISTRICT_TYPE[key] };
      });
    case 'NT':
      return Object.keys(NT_DISTRICT_TYPE).map(key => {
        return { value: key, label: NT_DISTRICT_TYPE[key] };
      });
    default:
      break;
  }
  return [];
};

export const renderTransportPaymentStatus = (
  status: TransportPaymentStatus,
) => {
  return (
    <div className="font-size-14">
      {status === 'Draft' ? (
        <Badge pill className="badge-soft-success ms-1 p-1 font-size-12">
          待運輸
        </Badge>
      ) : null}
      {status === 'Pending' || status === 'Failed' || status === 'Canceled' ? (
        <Badge pill className="badge-soft-warning ms-1 p-1 font-size-12">
          待付款
        </Badge>
      ) : null}
      {status === 'Completed' ? (
        <Badge pill className="badge-soft-success ms-1 p-1 font-size-12">
          付款完成
        </Badge>
      ) : null}
      {status === 'InProgress' ? (
        <Badge pill className="badge-soft-primary ms-1 p-1 font-size-12">
          待確認單據
        </Badge>
      ) : null}
      {status === 'AdminCanceled' ? (
        <Badge pill className="badge-soft-secondary ms-1 p-1 font-size-12">
          運輸取消
        </Badge>
      ) : null}
    </div>
  );
};

export const renderStatus = (status: TransportStatus, txt?: string) => {
  return (
    <h5 className="font-size-14 mb-1">
      {status === 'Pending' ? (
        <Badge pill className="badge-soft-warning ms-1 p-1 font-size-12">
          {txt}待運輸
        </Badge>
      ) : null}

      {status === 'Completed' ? (
        <Badge pill className="badge-soft-success ms-1 p-1 font-size-12">
          {txt}已完成
        </Badge>
      ) : null}

      {status === 'Canceled' ? (
        <Badge pill className="badge-soft-danger ms-1 p-1 font-size-12">
          {txt}已取消
        </Badge>
      ) : null}
      {/* {status === 'InProgress' ? (
        <Badge pill className="badge-soft-info ms-1 p-1 font-size-12">
          {txt}進行中
        </Badge>
      ) : null}
      {status === 'Failed' ? (
        <Badge pill className="badge-soft-secondary ms-1 p-1 font-size-12">
          {txt}失敗
        </Badge>
      ) : null}
      {status === 'Drafted' ? (
        <Badge pill className="badge-soft-primary ms-1 p-1 font-size-12">
          {txt}已登記
        </Badge>
      ) : null} */}
    </h5>
  );
};

export const renderPickupTransportStatus = (
  status: PickupTransportStatusType,
  txt?: string,
) => {
  return (
    <h5 className="font-size-14 mb-1">
      {status === 'Pending' ? (
        <Badge pill className="badge-soft-warning ms-1 p-1 font-size-12">
          {txt}待處理
        </Badge>
      ) : null}

      {status === 'Completed' ? (
        <Badge pill className="badge-soft-success ms-1 p-1 font-size-12">
          {txt}運輸完成
        </Badge>
      ) : null}
      {status === 'Canceled' ? (
        <Badge pill className="badge-soft-success ms-1 p-1 font-size-12">
          {txt}運輸取消
        </Badge>
      ) : null}
    </h5>
  );
};

export const renderPickupPaymentStatus = (
  status: PickupPaymentStatusType,
  txt?: string,
) => {
  return (
    <h5 className="font-size-14 mb-1">
      {status === 'Pending' || status === 'Failed' || status === 'Canceled' ? (
        <Badge pill className="badge-soft-warning ms-1 p-1 font-size-12">
          {txt}待付款
        </Badge>
      ) : null}

      {status === 'Completed' ? (
        <Badge pill className="badge-soft-success ms-1 p-1 font-size-12">
          {txt}待客戶取貨
        </Badge>
      ) : null}
      {status === 'InProgress' ? (
        <Badge pill className="badge-soft-primary ms-1 p-1 font-size-12">
          {txt}待確認單據
        </Badge>
      ) : null}
      {status === 'Drafted' ? (
        <Badge pill className="badge-soft-info ms-1 p-1 font-size-12">
          {txt}待收貨
        </Badge>
      ) : null}
      {status === 'AdminCompleted' ? (
        <Badge pill className="badge-soft-dark ms-1 p-1 font-size-12">
          {txt}客戶已取貨
        </Badge>
      ) : null}
      {status === 'AdminCanceled' ? (
        <Badge pill className="badge-soft-secondary ms-1 p-1 font-size-12">
          {txt}收貨取消
        </Badge>
      ) : null}
    </h5>
  );
};

export const renderClientShouldPayPrice = (
  TCost: any,
  paymentMethod: string,
) => {
  if (TCost) {
    if (paymentMethod === 'bankTransfer') {
      return formatPrice(convertToClientPrice(TCost.cost));
    }
    if (paymentMethod === 'alipay') {
      return formatPrice(convertToClientPrice(TCost.cost * (1 + 0.009)));
    }
    if (paymentMethod === 'wechatpay') {
      return formatPrice(convertToClientPrice(TCost.cost * (1 + 0.008)));
    }
    if (paymentMethod === 'stripe') {
      return formatPrice(
        convertToClientPrice(TCost.cost + TCost.transactionFee),
      );
    }
  }
  return '';
};

export const renderTransactionFee = (TCost: any, paymentMethod: string) => {
  if (TCost) {
    if (paymentMethod === 'bankTransfer') {
      return formatPrice(0);
    }
    if (paymentMethod === 'alipay') {
      return formatPrice(convertToClientPrice(TCost.cost * 0.009));
    }
    if (paymentMethod === 'wechatpay') {
      return formatPrice(convertToClientPrice(TCost.cost * 0.008));
    }
    if (paymentMethod === 'stripe') {
      return formatPrice(convertToClientPrice(TCost.transactionFee));
    }
  }
  return '';
};

import Axios from './';
import { ListResult, User, Filter, WalletRecord, UserProfile } from '../models';

/**
* * Get User List (https://cmhk-wine-dev.appicidea.com/doc/#/User/get_v1_user)
* * q: keyword filter, include name and phone
+*/

export interface GetUserListParams extends Filter {
  q?: string | undefined;
  userid?: string;
  phone?: string;
  email?: string | undefined;
  showHasRentalOnly?: boolean;
}

export const getUserList = async (params: GetUserListParams) => {
  const { data } = await Axios.get<ListResult<User>>('/user', { params });
  return data;
};

/**
* * Create User (https://cmhk-wine-dev.appicidea.com/doc/#/User/post_v1_user)
+*/

export interface CreateUserParams {
  email: string;
  phone?: string;
  displayName?: string;
  rentalid?: string;
  password?: string;
}

export const CreateUser = async (params: CreateUserParams) => {
  const { data } = await Axios.post<string>('/user', params);
  return data;
};

/**
* * Update User avatar (https://cmhk-wine-dev.appicidea.com/doc/#/User/patch_v1_user_profile)
+*/

export interface AvatarParams {
  avatar: string;
}

export const updateUserAvatar = async (
  userid: number,
  params: AvatarParams,
) => {
  const { data } = await Axios.patch<string>(`/user/${userid}`, params);
  return data;
};

/**
* * delete User (https://cmhk-wine-dev.appicidea.com/doc/#/User/delete_v1_user__userid_)
+*/

export const deleteUser = async (userid: number) => {
  const { data } = await Axios.delete<string>(`user/${userid}`);
  return data;
};

/**
* * Update User (https://cmhk-wine-dev.appicidea.com/doc/#/User/patch_v1_user_profile)
+*/

export interface UpdateUserParams {
  email: string;
  phone: string;
  displayName: string;
  rentalid?: number | null;
}

export const updateUser = async (userid: number, params: UpdateUserParams) => {
  const { data } = await Axios.patch<string>(`/user/${userid}`, params);
  return data;
};

export interface UpdateUserPasswordParams {
  password: string;
}

export const updateUserPassword = async (
  userid: number,
  params: UpdateUserPasswordParams,
) => {
  const { data } = await Axios.patch<string>(`/user/${userid}`, params);
  return data;
};

/**
* * get user wallet history (https://cmhk-wine-dev.appicidea.com/doc/#/User/get_v1_user__userid__walletHistory)
+*/

export const getUserWalletHistory = async (
  userid: number | string,
  params: Filter,
) => {
  const { data } = await Axios.get<ListResult<WalletRecord>>(
    `/user/${userid}/walletHistory`,
    { params },
  );
  return data;
};

/**
* * creat wallet history (https://cmhk-wine-dev.appicidea.com/doc/#/User/get_v1_user__userid__walletHistory)
+*/

export interface CreateWalletHistoryParams {
  userid: number;
  walletBalanceChanges: number;
}

export const createWalletHistory = async (
  userid: number,
  params: CreateWalletHistoryParams,
) => {
  const { data } = await Axios.post<string>(
    `/user/${userid}/walletHistory`,
    params,
  );
  return data;
};

// /**
// * * update wallet history (https://cmhk-wine-dev.appicidea.com/doc/#/User/patch_v1_user__userid__walletHistory__walletHistoryid_)
// +*/

// export const updateWalletHistory = async (
//   userid: number,
//   walletHistoryid: number,
//   params: { walletBalanceChanges: number },
// ) => {
//   const { data } = await Axios.patch<string>(
//     `/user/${userid}/walletHistory/${walletHistoryid}`,
//     params,
//   );
//   return data;
// };

/**
* * delete wallet history (https://cmhk-wine-dev.appicidea.com/doc/#/User/delete_v1_user__userid__walletHistory__walletHistoryid_)
+*/

export const deleteWalletHistory = async (
  userid: number,
  walletHistoryid: number,
) => {
  const { data } = await Axios.delete<string>(
    `/user/${userid}/walletHistory/${walletHistoryid}`,
  );
  return data;
};

/**
* * get one user (https://cmhk-wine-dev.appicidea.com/doc/#/User/get_v1_user__userid_)
+*/

export const getOneUser = async (userid: string) => {
  const { data } = await Axios.get<UserProfile>(`/user/${userid}`);
  return data;
};

/**
* * get one user sub account (https://cmhk-wine-dev.appicidea.com/doc/#/User/get_v1_user__userid_)
+*/

export const getOneUserSubAccount = async (userid: string) => {
  const { data } = await Axios.get<User[]>(`/user/${userid}/subAccount`);
  return data;
};

/**
* * get one user sub account (https://cmhk-wine-dev.appicidea.com/doc/#/User/get_v1_user__userid_)
+*/

export const addOneSubAccountToUser = async (
  userid: string,
  subUserid: string,
) => {
  const { data } = await Axios.post<any>(
    `/user/${userid}/subAccount/${subUserid}`,
  );
  return data;
};

/**
* * get one user sub account (https://cmhk-wine-dev.appicidea.com/doc/#/User/get_v1_user__userid_)
+*/

export const deleteOneSubAccountFromUser = async (
  userid: string,
  subUserid: string,
) => {
  const { data } = await Axios.delete<any>(
    `/user/${userid}/subAccount/${subUserid}`,
  );
  return data;
};

import React from 'react';
import { Table } from 'reactstrap';

interface Props {
  columns: any[];
  data: any[];
  keyField: string;
}

function CustomTable({ columns, data, keyField }: Props) {
  return (
    <Table className="table align-middle table-nowrap table-hover">
      <thead className="table-light">
        <tr>
          {columns.map((column, index) => (
            <th key={column.text + index}>{column.text}</th>
          ))}
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {data.map(item => {
            return (
              <tr key={item[keyField]}>
                {columns.map((column, index) => {
                  if (column.formatter) {
                    return (
                      <td key={column.text + item[keyField] + index}>
                        {column.formatter(item)}
                      </td>
                    );
                  }
                  return (
                    <td key={column.text + item[keyField] + index}>
                      {item[column.dataField]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      ) : null}
    </Table>
  );
}

export default CustomTable;

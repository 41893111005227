export type localStorageType =
  | 'cmhk-admin-promotion'
  | 'cmhk-admin-transport'
  | 'cmhk-admin-pickup';

export interface ValuePair {
  prev: StorageData | null;
  current: StorageData | null;
}

export interface StorageData {
  id: number | null;
  updateAt: string | null;
}

export const setLocalStorage = (key: localStorageType, value: ValuePair) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: localStorageType) => {
  const text = localStorage.getItem(key);
  return text && JSON.parse(text);
};

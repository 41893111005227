import { Redirect } from 'react-router-dom';

import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import {
  UserListPage,
  UserFormPage,
  UserWalletListPage,
} from 'app/pages/UserPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import {
  RentalListPage,
  RentalFormPage,
  RentalDetailListPage,
  RentalHistoryFormPage,
  RentalHistoryListPage,
} from 'app/pages/RentalPage';
import {
  AdsListPage,
  AdsRequestPage,
  AdsFormPage,
  AdsRequestFormPage,
  AdsCaegoryListPage,
} from 'app/pages/AdsPage';
import {
  TransportRequestListPage,
  TransportRecordListPage,
  TransportRequestFormPage,
  TransportRecordFormPage,
} from 'app/pages/TransportPage';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { AdsCategoryFormPage } from 'app/pages/AdsPage/AdsCategoryFormPage';
import {
  PickupRequestListPage,
  PickupFormPage,
  PickupEditFormPage,
} from 'app/pages/PickupPage';
import { AppUserForgetPWPage } from 'app/pages/AppUserPage';
import {
  FloorPlanFormPage,
  FloorPlanEditFormPage,
  FloorPlanListPage,
} from 'app/pages/FloorPlanPage';
import PrivacyPolicyPage from 'app/pages/PrivacyPolicyPage';
import { NotificationListPage } from 'app/pages/NotificationPage';
import { NotificationFormPage } from 'app/pages/NotificationPage/NotificationFormPage/Loadable';
import { TakeDownPage } from 'app/pages/TakeDownFormPage';
import { TakeDownDetailPage, TakeDownListPage } from 'app/pages/TakeDownPage';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
}
const userRoutes: Route[] = [
  // admin
  { path: '/admins', component: AdminListPage, exact: true },
  { path: '/admins/new', component: AdminFormPage },
  { path: '/admins/edit/:adminid', component: AdminFormPage },
  {
    path: '/admins/edit/:adminid/:password',
    component: AdminFormPage,
  },

  // user
  { path: '/users', component: UserListPage, exact: true },
  { path: '/users/new', component: UserFormPage },
  { path: '/users/edit/:userid', component: UserFormPage },
  { path: '/users/edit/:userid/wallet', component: UserWalletListPage },

  // rental
  { path: '/rental', component: RentalListPage },
  { path: '/rental/new', component: RentalFormPage },
  { path: '/rental/edit/:rentalid', component: RentalFormPage },
  { path: '/rental/renew/:rentalid', component: RentalFormPage },
  { path: '/rental/detail/:rentalid', component: RentalDetailListPage },
  { path: '/rentalHistory', component: RentalHistoryListPage },
  { path: '/rental/history/:rentalid/new', component: RentalHistoryFormPage },
  {
    path: '/rental/history/:rentalid/edit/:rentalHistoryid',
    component: RentalHistoryFormPage,
  },

  // Promotion
  { path: '/promotion', component: AdsListPage, exact: true },
  { path: '/promotion/new', component: AdsFormPage },
  { path: '/promotion/edit/:promotionid', component: AdsFormPage },
  { path: '/promotionRequest', component: AdsRequestPage },
  { path: '/promotionCategory', component: AdsCaegoryListPage },
  { path: '/promotionCategory/new', component: AdsCategoryFormPage },
  {
    path: '/promotionCategory/edit/:categoryid',
    component: AdsCategoryFormPage,
  },
  {
    path: '/promotionRequest/edit/:promotionRequestid',
    component: AdsRequestFormPage,
  },

  // transport
  { path: '/transportRequest', component: TransportRequestListPage },
  { path: '/transportRequest/new', component: TransportRequestFormPage },
  { path: '/transportRecord', component: TransportRecordListPage },
  {
    path: '/transportRequest/edit/:transportRequestid',
    component: TransportRequestFormPage,
  },
  {
    path: '/transportRecord/edit/:transportRequestid',
    component: TransportRecordFormPage,
  },

  // pick up
  { path: '/pickupRequest', component: PickupRequestListPage },
  { path: '/pickupRequest/new', component: PickupFormPage },
  {
    path: '/pickupRequest/edit/:packageReceiveRegistrationid',
    component: PickupEditFormPage,
  },

  // floor plan
  { path: '/floorPlan', component: FloorPlanListPage },
  { path: '/floorPlan/new', component: FloorPlanEditFormPage },
  { path: '/floorPlan/edit/:floorPlanid', component: FloorPlanEditFormPage },
  { path: '/floorPlan/detail/:floorPlanid', component: FloorPlanFormPage },

  // notification
  { path: '/notification', component: NotificationListPage },
  { path: '/notification/new', component: NotificationFormPage },

  {
    path: '/take-down',
    component: TakeDownListPage,
  },
  {
    path: '/take-down/detail/:takedownId',
    component: TakeDownDetailPage,
  },

  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/privacyPolicy', component: PrivacyPolicyPage },
  { path: '/takedown', component: TakeDownPage },
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/firebase/action', component: AppUserForgetPWPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  AutoCompleteSelectField,
  DateField,
  InputField,
  SwitchField,
} from 'app/components/Form';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import {
  createPackageReceive,
  CreatePackageReceiveParams,
} from 'app/services/PickupServices';
import { changePreloader } from 'store/Layout';
import { APP_NAME, PAGE_SIZE } from 'app/config';
import { PickupPaymentStatusType, User, OptionItems } from 'app/models';
import moment from 'moment';
import { convertToServerPrice } from 'app/helpers/CommonHelper';
import { getUserList } from 'app/services/UserService';

const Schema = Yup.object().shape({
  box: Yup.number().min(1, '不可少於一箱').nullable().required('必填項目'),
  cost: Yup.number()
    .nullable()
    .min(4, '不可少於HKD 4')
    .max(999999, '不可大於HKD 999,999.00'),
  displayName: Yup.string()
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元')
    .required('必填項目'),
  pickupDate: Yup.date().nullable(),
  expectedDeliveryDate: Yup.date().nullable().required('必填項目'),
  lockerNumber: Yup.string()
    .min(1, '最少要1個字元')
    .nullable()
    .max(100, '不可多於100個字元'),
  remarks: Yup.string()
    .min(2, '少要2個字元')
    .nullable()
    .max(500, '不可多於500個字元'),
  userid: Yup.number().nullable().required('必填項目'),
  passcode: Yup.string()
    .nullable()
    .min(1, '最少要2個字元')
    .when('paymentStatus', (paymentStatus, passSchema) =>
      paymentStatus === 'Completed'
        ? passSchema.required('必填項目')
        : passSchema,
    ),
});

type FormItem = {
  displayName: string;
  lockerNumber: string;
  pickupDate: Date | Date[] | null;
  box: number | null;
  expectedDeliveryDate: Date | Date[] | null;
  paymentMethod: string | null;
  remarks: string;
  images?: any;
  cost?: number | null;
  rentalid: number | null;
  passcode: string | null;
  paymentStatus?: PickupPaymentStatusType;
  receiptImages?: any[];
  // pickupDate: Date | Date[] | null;
  clientRemarks: string;
  userid: number | null;
  isFree: boolean;
};

const FORM_ITEM = {
  displayName: '',
  lockerNumber: '',
  pickupDate: null,
  box: null,
  expectedDeliveryDate: null,
  paymentMethod: null,
  remarks: '',
  images: [],
  cost: null,
  rentalid: null,
  Cellar: [],
  passcode: '',
  receiptImages: [],
  // pickupDate: null,
  clientRemarks: '',
  userid: null,
  isFree: false,
};

export const PickupFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const [form] = useState<FormItem>(FORM_ITEM);
  const [userText, setUserText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [userList, setUserList] = useState<OptionItems[]>([]);

  const initForm = useCallback(async () => {
    try {
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const renderParams = (values: FormItem) => {
    const {
      displayName,
      lockerNumber,
      pickupDate,
      box,
      expectedDeliveryDate,
      paymentMethod,
      remarks,
      cost,
      passcode,
      clientRemarks,
      userid,
      isFree,
    } = values;
    const params: CreatePackageReceiveParams = {
      displayName,
      lockerNumber,
      pickupDate: pickupDate
        ? moment(pickupDate[0]).format('YYYY-MM-DD')
        : null,
      box: box ? box : null,
      expectedDeliveryDate: expectedDeliveryDate
        ? moment(expectedDeliveryDate[0]).format('YYYY-MM-DD')
        : '',
      paymentMethod,
      remarks,
      passcode: passcode ? passcode : '',
      cost: isFree ? 0 : cost ? convertToServerPrice(cost) : null,
      // pickupDate: pickupDate ? moment(pickupDate[0]).toISOString() : '',
      clientRemarks,
      userid: userid!,
    };
    if (isFree) {
      params.paymentStatus = 'Completed';
    }
    return params;
  };

  const createNewPackageReceive = async (values: FormItem) => {
    setLoading(true);
    try {
      const params = renderParams(values);
      await createPackageReceive(params);
      setLoading(false);
      toast.success('新增收貨申請成功。');
      history.push('/pickupRequest');
    } catch (err) {
      setLoading(false);
      toast.warning('新增收貨申請失敗，請重試。');
    }
  };

  const onSubmit = async (values: FormItem) => {
    createNewPackageReceive(values);
  };

  const scrollToBottom = async () => {
    let offset = 0;
    if (userList.length === 0) {
      offset = 0;
    } else {
      offset = Math.ceil(userList.length / PAGE_SIZE) * PAGE_SIZE;
    }
    try {
      const userRes = await getUserList({
        limit: PAGE_SIZE,
        offset: offset,
        showHasRentalOnly: true,
      });
      formatUserList(userRes.rows, true);
    } catch (err) {
      console.log(err);
    }
  };

  const userTextOnChange = async (text: string) => {
    if (text !== userText) {
      setUserText(text);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    _.debounce(async (text: string) => {
      try {
        const userRes = await getUserList({
          limit: PAGE_SIZE,
          offset: 0,
          q: text,
          showHasRentalOnly: true,
        });
        formatUserList(userRes.rows);
      } catch (err) {
        console.log(err);
      }
    }, 500),
    [],
  );

  const formatUserList = (users: User[], concat?: boolean) => {
    const formatedUserList: OptionItems[] = users.map(user => ({
      label: `#${user.userid} | ${user.displayName || ' - '} | ${user.email}`,
      value: user.userid,
    }));
    if (concat) {
      const cUserList = [...userList];
      setUserList(cUserList.concat(formatedUserList));
    } else {
      setUserList(formatedUserList);
    }
  };

  useEffect(() => {
    debounceSearch(userText);
  }, [userText, debounceSearch]);

  const TITLE = '新增收貨申請';
  const BreadcrumbsOptions = [
    { title: '收貨申請', path: '/pickupRequest' },
    { title: '收貨申請列表', path: '/pickupRequest' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs title={TITLE} breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({ values, setFieldValue, setFieldError, errors }) => (
                          <Form className="form-horizontal">
                            <CardTitle>租約資料</CardTitle>
                            <Row>
                              <Col lg={6} sm={12}>
                                <AutoCompleteSelectField
                                  name="userid"
                                  label="會員"
                                  placeholder="搜尋有租約的會員 #編號 | 名稱 | 電郵"
                                  inputValue={userText}
                                  onInputChange={userTextOnChange}
                                  options={userList}
                                  selectOnChange={(e: OptionItems) => {
                                    setFieldValue('userid', e.value);
                                  }}
                                  onMenuScrollToBottom={scrollToBottom}
                                />
                              </Col>
                              <Col lg={6} sm={12}>
                                <InputField
                                  name="displayName"
                                  label="收貨人姓名"
                                  placeholder="輸入收貨人姓名"
                                  type="text"
                                />
                              </Col>
                            </Row>
                            <hr />
                            <CardTitle>收貨資料</CardTitle>
                            <Row>
                              <Col lg={6} sm={12}>
                                <InputField
                                  name="box"
                                  label="預計箱數"
                                  placeholder="10"
                                  type="number"
                                />
                              </Col>
                              <Col lg={6} sm={12}>
                                <DateField
                                  name="expectedDeliveryDate"
                                  label="預計送貨日期"
                                  placeholder="預計送貨日期"
                                />
                              </Col>
                            </Row>
                            <hr />
                            <CardTitle>取貨資料(選填)</CardTitle>
                            <Row>
                              <Col lg="6">
                                <SwitchField name="isFree" label="免費申請" />
                              </Col>
                            </Row>
                            {!values.isFree ? (
                              <Row>
                                {/* <Col lg="6">
                                  <SelectField
                                    name="paymentMethod"
                                    label="付款方式"
                                    placeholder="付款方式"
                                    options={Object.keys(
                                      TransportPaymentMethod,
                                    ).map(key => {
                                      return {
                                        value: key,
                                        label: TransportPaymentMethod[key],
                                      };
                                    })}
                                  />
                                </Col> */}
                                <Col lg="6">
                                  <InputField
                                    name="cost"
                                    label="費用"
                                    placeholder="費用"
                                  />
                                </Col>
                                <Col lg={6} sm={12}>
                                  <DateField
                                    name="pickupDate"
                                    label="入酒日期"
                                    placeholder="選擇入酒日期"
                                  />
                                </Col>
                              </Row>
                            ) : null}

                            <Row>
                              <Col lg={6} sm={12}>
                                <InputField
                                  name="lockerNumber"
                                  label="自取櫃編號"
                                  placeholder="輸入自取櫃編號"
                                  type="text"
                                />
                              </Col>
                              <Col lg={6} sm={12}>
                                <InputField
                                  name="passcode"
                                  label="自取櫃密碼"
                                  placeholder="輸入自取櫃密碼"
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={12}>
                                <InputField
                                  name="remarks"
                                  label="備註"
                                  placeholder="輸入備註"
                                  type="textarea"
                                />
                              </Col>
                            </Row>
                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { PAGE_SIZE } from 'app/config';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  count: number;
  current: number;
  onChange?: (page: number) => void;
}

function CustomPagination({ count, current, onChange }: Props) {
  const history = useHistory();
  const location = useLocation();
  const pageOnChange = (page: number) => {
    if (onChange) {
      onChange(page);
    } else {
      const params = new URLSearchParams(location.search);
      params.set('page', page.toString());
      history.push({ search: params.toString() });
    }
  };
  const total = Math.ceil(count / PAGE_SIZE);
  let pages: number[] = [];
  for (let i = 0; i < total; i++) {
    pages.push(i + 1);
  }
  const nextDisable = current === total;
  const prevDisable = current === 1;
  return (
    <Pagination aria-label="Page navigation example">
      {pages.length > 0 ? (
        <PaginationItem
          disabled={prevDisable}
          onClick={() => {
            if (!prevDisable) {
              pageOnChange(current - 1);
            }
          }}
        >
          <PaginationLink previous href="#" style={{ fontSize: 20 }} />
        </PaginationItem>
      ) : null}
      {pages.map(page => (
        <PaginationItem
          active={page === current}
          onClick={() => pageOnChange(page)}
          key={page}
          title={page.toString()}
        >
          <PaginationLink href="#">{page}</PaginationLink>
        </PaginationItem>
      ))}
      {pages.length > 0 ? (
        <PaginationItem
          disabled={nextDisable}
          onClick={() => {
            if (!nextDisable) {
              pageOnChange(current + 1);
            }
          }}
          title="next page"
        >
          <PaginationLink next href="#" style={{ fontSize: 20 }} />
        </PaginationItem>
      ) : null}
    </Pagination>
  );
}

export default CustomPagination;

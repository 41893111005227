import { IMAGE_URL } from 'app/config';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const formatPrice = (price: number, abs?: boolean) => {
  if (price) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if (abs) {
      const ABSPrice = Math.abs(price);
      return dollarUS.format(ABSPrice);
    }
    return dollarUS.format(price);
  }
  if (price === 0) {
    return '$0.00';
  }
  return '-';
};

export const convertToServerPrice = (price: number) => {
  if (price) {
    return price * 100;
  }
  return price;
};

export const convertToClientPrice = (price: number) => {
  if (price) {
    return price / 100;
  }
  return price;
};

export const removeEmptyField = (value: object | null) => {
  if (value) {
    const temp: any = {};
    Object.keys(value).forEach(key => {
      if (value[key]) {
        temp[key] = value[key];
      }
    });
    return temp;
  }
  return value;
};

export const formatTime = (time: string) => {
  if (time) {
    let str = time.split(':');
    return `${str[0]}:${str[1]}`;
  }
  return '';
};

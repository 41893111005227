import React, { useEffect } from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import _ from 'lodash';

import Select from 'react-select';

import { Label } from 'reactstrap';
import { GroupedOption, OptionItems } from 'app/models';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  groupedOptions: GroupedOption[];
  selectOnChange?: (value) => void;
  isClearable?: boolean;
  disabled?: boolean;
  watch?: () => void;
}

const GroupSelectField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    groupedOptions,
    selectOnChange,
    isClearable,
    disabled,
    watch,
  } = props;
  useEffect(() => {
    if (watch) {
      watch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);
  const rendervalue = () => {
    let tempArr: OptionItems[] = [];
    groupedOptions.forEach(group => {
      tempArr = tempArr.concat(group.options);
    });
    const index = _.findIndex(tempArr, option => option.value === field.value);
    if (index > -1) {
      return tempArr[index];
    }
    return null;
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Select
        defaultValue={rendervalue()}
        isMulti={false}
        placeholder={placeholder}
        options={groupedOptions}
        classNamePrefix="select2-selection"
        {...field}
        value={rendervalue()}
        onChange={e => {
          if (e) {
            helpers.setValue(e.value as string);
          } else {
            helpers.setValue('');
          }
          if (selectOnChange) {
            selectOnChange(e?.value);
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default GroupSelectField;

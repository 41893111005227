import * as CSS from 'csstype';
import { TimeStamp } from '.';

export interface FloorPlan extends TimeStamp, FloorPlanBasic {
  floorPlanid: number;
  Cells?: Cell[] | null;
  cells?: Cell[] | null;
}

export interface FloorPlanDisplay extends FloorPlanBasic {
  floorPlanid?: number;
  Cells: CellBasic[] | null;
}

export interface FloorPlanBasic {
  floorPlanName: string;
  numberOfCol: number;
  numberOfRow: number;
}

export interface Cell extends TimeStamp, CellBasic {
  FloorPlan?: FloorPlan;
}

export interface CellBasic {
  cellid: number;
  cellName: string | null;
  roomid: number | null;
  floorPlanid: number;
  x: number;
  y: number;
  status: string;
  style?: CSS.StandardShorthandProperties | {};
  remarks?: string;
}

export enum CellStatus {
  empty = '(空白)',
  disabled = '禁用',
  enabled = '可用',
  unavailable = '不可用',
  rented = '已租',
  remarked = '已預約',
}

export type CellStatusType = keyof typeof CellStatus;

export type floorPlanMode = 'drawer' | 'editer' | 'view';

export const CellStatusForDrawerSelect = [
  'empty',
  'disabled',
  'enabled',
  'unavailable',
];
export const CellStatusForDrawerStat = [
  'enabled',
  'rented',
  'remarked',
  'unavailable',
  'disabled',
];
export const CellStatusForEditerSelect = ['enabled', 'unavailable'];
export const CellStatusForEditerStat = [
  'enabled',
  'rented',
  'remarked',
  'unavailable',
];

//Real one color
export const CellStatusStyle = {
  empty: {},
  disabled: {
    backgroundColor: '#eff2f7',
    color: 'black',
  },
  enabled: {
    backgroundColor: '#fafafa',
    color: 'black',
  },
  rented: {
    backgroundColor: '#34c38f',
    color: 'white',
  },
  remarked: {
    backgroundColor: '#f1b44c',
    color: 'white',
  },

  unavailable: {
    backgroundColor: '#f46a6a',
    color: 'white',
  },
};

//Default Data of Object
export const DefaultFloorPlanDisplay: FloorPlanDisplay = {
  floorPlanid: 0,
  floorPlanName: '',
  numberOfCol: 0,
  numberOfRow: 0,
  Cells: [],
};
//Default Data of Object
export const DefaultFloorPlan: FloorPlan = {
  floorPlanid: 0,
  floorPlanName: '',
  numberOfCol: 10,
  numberOfRow: 10,
  Cells: [],
  createdAt: '',
  updatedAt: '',
  deletedAt: null,
};
export const DefaultCellDisplay: CellBasic = {
  cellid: 0,
  cellName: '',
  roomid: null,
  floorPlanid: 0,
  x: 0,
  y: 0,
  status: 'empty',
  remarks: '',
  style: {},
};

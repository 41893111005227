import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyle } from 'styles/global-styles';

import { useTranslation } from 'react-i18next';
import ProtectedRoute from 'app/route/ProtectedRoute';
import { authRoutes, userRoutes } from 'app/route/allRoutes';

// layouts Format
import VerticalLayout from 'app/components/VerticalLayout';
import HorizontalLayout from 'app/components/HorizontalLayout';
import NonAuthLayout from 'app/components/NonAuthLayout';

import { APP_NAME, TOAST_TIME } from 'app/config';
import { getProfile } from './services/AdminServices';
import Bugsnag from '@bugsnag/js';

export function App() {
  const auth = useCallback(async () => {
    if (localStorage.getItem('authUser')) {
      try {
        const res = await getProfile();
        Bugsnag.addMetadata('admin', { adminid: res.adminid });
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    // global auth checking
    auth();
  }, [auth]);
  const { layoutType } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const { i18n } = useTranslation();
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Layout = getLayout();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate={APP_NAME}
        defaultTitle={APP_NAME}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content={APP_NAME} />
      </Helmet>

      <Switch>
        {authRoutes.map((route, idx) => (
          <ProtectedRoute
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        <VerticalLayout>
          <Switch>
            {userRoutes.map((route, idx) => (
              <ProtectedRoute
                path={route.path}
                // layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </VerticalLayout>

        <Redirect from="*" to="/notFound" />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={TOAST_TIME}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyle />
    </BrowserRouter>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { renderPickupPaymentStatus } from 'app/helpers/TransportHelper';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';

import { Formik, Form, FormikHelpers } from 'formik';
import {
  AutoCompleteSelectField,
  DateField,
  ImageField,
  InputField,
  SelectField,
  SwitchField,
} from 'app/components/Form';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import {
  getOnePackageReceive,
  updatePackageReceive,
  UpdatePackageReceiveParams,
} from 'app/services/PickupServices';
import { changePreloader } from 'store/Layout';
import { APP_NAME, PAGE_SIZE } from 'app/config';
import { getRentalList } from 'app/services/RentalService';
import {
  Rental,
  PackageReceive,
  FileResponse,
  StripeLog,
  PaymentMethodOptions,
  PaymentTransactionFee,
  User,
  OptionItems,
  PickupPaymentStatusType,
} from 'app/models';
import moment from 'moment';
import Dialog from 'app/components/Modal/Modal';
import { uploadImage } from 'app/services/CommonService';
import {
  convertToClientPrice,
  convertToServerPrice,
} from 'app/helpers/CommonHelper';
import { Table } from 'app/components/Table';
import StripeLogsColumns from 'app/components/Rental/StripeLogsColumns';

import {
  BasicFormItem,
  BASIC_FORM_ITEM,
  BasicSchema,
  DraftedFormItem,
  DRAFTED_FORM_ITEM,
  DraftedSchema,
  StatusFormItem,
  STATUS_FORM_ITEM,
  StatusSchema,
  DraftedPickupPaymentStatusOptions,
} from './form';
import { getUserList } from 'app/services/UserService';

interface PackageReceiveFormQuery {
  packageReceiveRegistrationid?: string;
}

export const PickupEditFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<PackageReceiveFormQuery>();
  const [packageReceiveRegistrationid, setPackageReceiveRegistrationid] =
    useState<number | null>(null);
  const [form, setForm] = useState<BasicFormItem>(BASIC_FORM_ITEM);
  const [draftForm, setDraftForm] =
    useState<DraftedFormItem>(DRAFTED_FORM_ITEM);
  const [statusForm, setStatusForm] =
    useState<StatusFormItem>(STATUS_FORM_ITEM);
  const [request, setRequest] = useState<PackageReceive | null>(null);

  const [logs, setLogs] = useState<StripeLog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rentalList, setRentalList] = useState<Rental[]>([]);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [isHideLogs, setIsHideLogs] = useState<boolean>(true);
  const [userText, setUserText] = useState<string>('');
  const [userList, setUserList] = useState<OptionItems[]>([]);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { packageReceiveRegistrationid: packageReceiveRegistrationID } =
      params;
    try {
      if (packageReceiveRegistrationID) {
        const packageRes = await getOnePackageReceive(
          packageReceiveRegistrationID,
        );
        const {
          box,
          pickupDate,
          lockerNumber,
          cost,
          displayName,
          paymentMethod,
          packageReceiveRegistrationid,
          passcode,
          paymentStatus,
          expectedDeliveryDate,
          remarks,
          images,
          StripeLogs,
          receiptImages,
          clientRemarks,
          User,
          userid,
        } = packageRes;

        const form: BasicFormItem = {
          paymentStatus: paymentStatus as PickupPaymentStatusType,
          userid: userid,
          cost: cost ? convertToClientPrice(cost) : cost === 0 ? 0 : null,
          passcode,
          pickupDate: pickupDate
            ? [moment(pickupDate, 'YYYY-MM-DD').toDate()]
            : null,
          displayName,
          box,
          lockerNumber,
          expectedDeliveryDate: expectedDeliveryDate
            ? [moment(expectedDeliveryDate, 'YYYY-MM-DD').toDate()]
            : null,
          isEditable: false,
          paymentMethod,
          clientRemarks,
          receiptImages:
            receiptImages && receiptImages.length > 0
              ? receiptImages.map(image => ({
                  preview: image,
                  name: '',
                }))
              : [],
        };
        const draftForm: DraftedFormItem = {
          cost: cost === 0 ? 0 : cost ? convertToClientPrice(cost) : '',
          isFree: cost === 0 ? true : false,
          passcode: passcode ? passcode : '',
          pickupDate: pickupDate
            ? [moment(pickupDate, 'YYYY-MM-DD').toDate()]
            : [moment().toDate()],
          images:
            images && images.length > 0
              ? images.map(image => ({
                  preview: image,
                  name: '',
                }))
              : [],
          remarks,
          paymentStatus,
          lockerNumber,
          isEditable:
            paymentStatus === 'Drafted' || paymentStatus === 'InProgress',
          receiptImages:
            receiptImages && receiptImages.length > 0
              ? receiptImages.map(image => ({
                  preview: image,
                  name: '',
                }))
              : [],
        };
        const statusForm: StatusFormItem = {
          paymentStatus,
          images:
            images && images.length > 0
              ? images.map(image => ({
                  preview: image,
                  name: '',
                }))
              : [],
          remarks,
        };
        setUserList([
          {
            label: User.displayName ? User.displayName : User.email,
            value: User.userid,
          },
        ]);
        // console.log(statusForm, draftForm, form);
        setStatusForm(statusForm);
        setUserText(User.displayName ? User.displayName : User.email);
        setDraftForm(draftForm);
        setForm(form);
        setRequest(packageRes);
        setPackageReceiveRegistrationid(packageReceiveRegistrationid);
        setLogs(StripeLogs);
      } else {
        toast.warning('找不到收貨申請');
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const onSubmit = async (
    values: BasicFormItem,
    action: FormikHelpers<BasicFormItem>,
  ) => {
    // basic form
    setLoading(true);
    const {
      displayName,
      box,
      expectedDeliveryDate,
      passcode,
      cost,
      lockerNumber,
    } = values;
    const data = {
      displayName,
      box,
      expectedDeliveryDate: expectedDeliveryDate
        ? moment(expectedDeliveryDate[0]).toISOString()
        : null,
      passcode: passcode ? passcode : '',
      cost: cost ? convertToServerPrice(cost) : null,
      lockerNumber,
    };
    try {
      await updatePackageReceive(packageReceiveRegistrationid!, data);
      toast.success('編輯申請資料成功。');
      action.setFieldValue('isEditable', false);
      setLoading(false);
    } catch (err) {
      toast.warning('編輯申請資料失敗，請重試。');
      setLoading(false);
    }
  };

  const onDraftFormSubmit = async (values: DraftedFormItem) => {
    // draft form
    setLoading(true);
    const {
      cost,
      passcode,
      pickupDate,
      images,
      remarks,
      paymentStatus,
      lockerNumber,
      isFree,
    } = values;
    let tempStatus = paymentStatus;
    if (tempStatus === 'Drafted') {
      tempStatus = 'Pending';
    } else if (tempStatus === 'InProgress') {
      tempStatus = 'Completed';
    }
    if (isFree) {
      tempStatus = 'Completed';
    }
    const data: UpdatePackageReceiveParams = {
      cost: isFree ? 0 : cost ? convertToServerPrice(cost) : null,
      passcode: passcode ? passcode : '',
      pickupDate: pickupDate
        ? moment(pickupDate[0]).format('YYYY-MM-DD')
        : null,
      remarks,
      paymentStatus: tempStatus,
      images: [],
      lockerNumber,
    };
    try {
      // images
      const imagePromiseArray: Promise<FileResponse | string>[] = [];
      const curImageArray: string[] = [];
      if (images && images.length > 0) {
        images.forEach(image => {
          if (image.size) {
            const formData = new FormData();
            formData.append('file', image);
            imagePromiseArray.push(uploadImage(formData));
          } else {
            imagePromiseArray.push(image.preview);
          }
        });
      }
      const imageRes = await Promise.all(imagePromiseArray);
      if (imageRes && imageRes.length > 0) {
        imageRes.forEach(res => {
          if (typeof res === 'object') {
            curImageArray.push(`${res.fileName}`);
          } else {
            curImageArray.push(res);
          }
        });
      }
      data.images = curImageArray;
      await updatePackageReceive(packageReceiveRegistrationid!, data);
      toast.success('編輯收貨資料成功。');
      setLoading(false);
      initForm();
    } catch (err) {
      toast.warning('編輯收貨資料失敗，請重試。');
      setLoading(false);
    }
  };

  const onStatusFormSubmit = async (values: StatusFormItem) => {
    // status form
    setLoading(true);
    const { paymentStatus, images, remarks } = values;
    const data: UpdatePackageReceiveParams = {
      remarks,
      paymentStatus,
      images: [],
    };
    try {
      // images
      const imagePromiseArray: Promise<FileResponse | string>[] = [];
      const curImageArray: string[] = [];
      if (images && images.length > 0) {
        images.forEach(image => {
          if (image.size) {
            const formData = new FormData();
            formData.append('file', image);
            imagePromiseArray.push(uploadImage(formData));
          } else {
            imagePromiseArray.push(image.preview);
          }
        });
      }
      const imageRes = await Promise.all(imagePromiseArray);
      if (imageRes && imageRes.length > 0) {
        imageRes.forEach(res => {
          if (typeof res === 'object') {
            curImageArray.push(`${res.fileName}`);
          } else {
            curImageArray.push(res);
          }
        });
      }
      data.images = curImageArray;
      await updatePackageReceive(packageReceiveRegistrationid!, data);
      toast.success('編輯處理付款及取貨成功。');
      setLoading(false);
      initForm();
    } catch (err) {
      toast.warning('編輯處理付款及取貨失敗，請重試。');
      setLoading(false);
    }
  };

  const deleteConfirm = async () => {
    setDeleteLoading(false);
    try {
      await updatePackageReceive(packageReceiveRegistrationid!, {
        paymentStatus: 'AdminCanceled',
      });
      toast.success('成功取消收貨申請');
      setDeleteLoading(false);
      setDeleteVisible(false);
      history.replace('/pickupRequest');
    } catch (err) {
      toast.warning('取消收貨申請失敗，請重試。');
      setDeleteLoading(false);
      setDeleteVisible(false);
    }
  };

  const scrollToBottom = async () => {
    let offset = 0;
    if (rentalList.length === 0) {
      offset = 0;
    } else {
      offset = Math.ceil(rentalList.length / PAGE_SIZE) * PAGE_SIZE;
    }
    try {
      const rentalRes = await getRentalList({
        limit: PAGE_SIZE,
        offset: offset,
      });
      const cRentalList = [...rentalList]; // current rental list
      setRentalList(cRentalList.concat(rentalRes.rows));
    } catch (err) {
      console.log(err);
    }
  };

  const userTextOnChange = async (text: string) => {
    if (text !== userText) {
      setUserText(text);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    _.debounce(async (text: string) => {
      try {
        const userRes = await getUserList({
          limit: PAGE_SIZE,
          offset: 0,
          q: text,
          showHasRentalOnly: true,
        });
        formatUserList(userRes.rows);
      } catch (err) {
        console.log(err);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    debounceSearch(userText);
  }, [userText, debounceSearch]);

  const renderLogs = () => {
    if (isHideLogs) {
      return logs.filter(
        log =>
          log.raw.type === 'payment_intent.payment_failed' ||
          log.raw.type === 'payment_intent.succeeded',
      );
    }
    return logs;
  };

  const renderTransactionFee = () => {
    if (request) {
      const { cost, transactionFee, paymentMethod } = request;
      return `客戶應付: HK$${convertToClientPrice(cost + transactionFee)} ${
        paymentMethod ? `(${PaymentTransactionFee[paymentMethod]})` : ''
      }`;
    }
    return '';
  };

  const formatUserList = (users: User[], concat?: boolean) => {
    const formatedUserList: OptionItems[] = users.map(user => ({
      label: `#${user.userid} | ${user.displayName || ' - '} | ${user.email}`,
      value: user.userid,
    }));
    if (concat) {
      const cUserList = [...userList];
      setUserList(cUserList.concat(formatedUserList));
    } else {
      setUserList(formatedUserList);
    }
  };

  const TITLE = `編輯收貨申請`;
  const BreadcrumbsOptions = [
    { title: '收貨申請', path: '/pickupRequest' },
    { title: '收貨申請列表', path: '/pickupRequest' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs title={TITLE} breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={BasicSchema}
                        onSubmit={onSubmit}
                      >
                        {({ values, setFieldValue, errors, setFieldError }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col
                                lg={6}
                                xs={9}
                                className="d-flex flex-row align-items-center justify-content-start"
                              >
                                <CardTitle>
                                  {request
                                    ? `單號${request.packageReceiveRegistrationid} `
                                    : '單號'}
                                  資料
                                </CardTitle>
                                {renderPickupPaymentStatus(
                                  request?.paymentStatus!,
                                )}
                              </Col>
                              {values.paymentStatus !== 'AdminCanceled' ? (
                                <Col
                                  lg={6}
                                  xs={3}
                                  className="d-flex flex-row align-items-center justify-content-end"
                                >
                                  <Button
                                    color="light"
                                    outline
                                    className="waves-effect"
                                    onClick={() =>
                                      setFieldValue(
                                        'isEditable',
                                        !values.isEditable,
                                      )
                                    }
                                  >
                                    {values.isEditable ? (
                                      'X'
                                    ) : (
                                      <i className="bx bx-edit-alt" />
                                    )}
                                  </Button>
                                </Col>
                              ) : null}
                            </Row>

                            <Row>
                              <Col lg={6} sm={12}>
                                <AutoCompleteSelectField
                                  name="userid"
                                  label="會員"
                                  placeholder="搜尋有租約的會員 #編號 | 名稱 | 電郵"
                                  inputValue={userText}
                                  onInputChange={userTextOnChange}
                                  options={userList}
                                  selectOnChange={(e: OptionItems) => {
                                    setFieldValue('userid', e.value);
                                  }}
                                  onMenuScrollToBottom={scrollToBottom}
                                  disabled={!values.isEditable}
                                />
                              </Col>
                              <Col lg={6} sm={12}>
                                <InputField
                                  name="displayName"
                                  label="收貨人姓名"
                                  placeholder="輸入收貨人姓名"
                                  type="text"
                                  disabled={!values.isEditable}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={6} sm={12}>
                                <InputField
                                  name="box"
                                  label="預計箱數"
                                  placeholder="10"
                                  type="number"
                                  disabled={!values.isEditable}
                                />
                              </Col>
                              <Col lg={6} sm={12}>
                                <DateField
                                  name="expectedDeliveryDate"
                                  label="預計送貨日期"
                                  placeholder="預計送貨日期"
                                  disabled={!values.isEditable}
                                />
                              </Col>
                            </Row>
                            <Row>
                              {values.paymentMethod ? (
                                <Col lg={6} sm={12}>
                                  <SelectField
                                    name="paymentMethod"
                                    label="付款方式"
                                    placeholder="付款方式"
                                    options={Object.keys(
                                      PaymentMethodOptions,
                                    ).map(key => {
                                      return {
                                        value: key,
                                        label: PaymentMethodOptions[key],
                                      };
                                    })}
                                    disabled={true}
                                  />
                                </Col>
                              ) : null}
                            </Row>
                            {request?.paymentStatus === 'Completed' ||
                            request?.paymentStatus === 'AdminCompleted' ||
                            request?.paymentStatus === 'AdminCanceled' ? (
                              <div>
                                <Row>
                                  <Col lg={6} sm={12}>
                                    <InputField
                                      name="cost"
                                      label="費用"
                                      placeholder="輸入費用"
                                      type="number"
                                      disabled={
                                        !values.isEditable || values.cost === 0
                                      }
                                    />
                                    <div className="text-muted mb-4">
                                      {renderTransactionFee()}
                                    </div>
                                  </Col>

                                  <Col lg={6} sm={12}>
                                    <DateField
                                      name="pickupDate"
                                      label="入酒日期"
                                      placeholder="入酒日期"
                                      disabled={!values.isEditable}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6} sm={12}>
                                    <InputField
                                      name="passcode"
                                      label="自取櫃密碼"
                                      placeholder="輸入密碼"
                                      type="text"
                                      disabled={!values.isEditable}
                                    />
                                  </Col>
                                  <Col lg={6} sm={12}>
                                    <InputField
                                      name="lockerNumber"
                                      label="自取櫃編號"
                                      placeholder="輸入自取櫃編號"
                                      type="text"
                                      disabled={!values.isEditable}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            ) : null}
                            {values.clientRemarks ? (
                              <div>
                                <hr />
                                <Row>
                                  <Col lg={12}>
                                    <InputField
                                      type="textarea"
                                      label="客戶留言"
                                      name="clientRemarks"
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </div>
                            ) : null}
                            {request?.paymentStatus !== 'Drafted' &&
                            request?.paymentStatus !== 'Pending' &&
                            request?.paymentStatus !== 'InProgress' ? (
                              <Row>
                                <Col lg={12}>
                                  <ImageField
                                    label="客戶單據"
                                    name="receiptImages"
                                    disabled={true}
                                  />
                                </Col>
                              </Row>
                            ) : null}
                            {values.isEditable ? (
                              <div className="mt-3 d-flex flex-row-reverse">
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="ms-1"
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <i className="bx bx-loader-circle bx-spin" />
                                  ) : (
                                    '提交'
                                  )}
                                </Button>
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible(true)}
                                >
                                  取消收貨申請
                                </Button>
                              </div>
                            ) : null}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {request?.paymentStatus === 'Completed' ||
          request?.paymentStatus === 'AdminCompleted' ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {!isPreloader ? (
                      <div className="p-2">
                        <Formik
                          initialValues={statusForm}
                          validationSchema={StatusSchema}
                          onSubmit={onStatusFormSubmit}
                        >
                          {() => (
                            <Form className="form-horizontal">
                              <Row>
                                <Col lg={12}>
                                  <CardTitle>處理取貨</CardTitle>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} sm={12}>
                                  <SelectField
                                    name="paymentStatus"
                                    label="付款狀態"
                                    placeholder="付款狀態"
                                    options={Object.keys(
                                      DraftedPickupPaymentStatusOptions,
                                    ).map(key => {
                                      return {
                                        value: key,
                                        label:
                                          DraftedPickupPaymentStatusOptions[
                                            key
                                          ],
                                      };
                                    })}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12} sm={12}>
                                  <InputField
                                    name="remarks"
                                    label="備註"
                                    placeholder="輸入備註"
                                    type="textarea"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12} sm={12}>
                                  <ImageField
                                    name="images"
                                    label="收貨圖片"
                                    placeholder="收貨圖片"
                                    multiple
                                  />
                                </Col>
                              </Row>

                              <div className="mt-3 d-flex flex-row-reverse">
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="ms-1"
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <i className="bx bx-loader-circle bx-spin" />
                                  ) : (
                                    '提交'
                                  )}
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}

          {packageReceiveRegistrationid &&
          (request?.paymentStatus === 'Drafted' ||
            request?.paymentStatus === 'Pending' ||
            request?.paymentStatus === 'Failed' ||
            request?.paymentStatus === 'Canceled' ||
            request?.paymentStatus === 'InProgress') ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {!isPreloader ? (
                      <div className="p-2">
                        <Formik
                          initialValues={draftForm}
                          validationSchema={DraftedSchema}
                          onSubmit={onDraftFormSubmit}
                        >
                          {({ setFieldValue, values }) => (
                            <Form className="form-horizontal">
                              <Row>
                                <Col
                                  lg={6}
                                  xs={9}
                                  className="d-flex flex-row align-items-center justify-content-start"
                                >
                                  <CardTitle>
                                    {request?.paymentStatus === 'InProgress'
                                      ? '確認付款'
                                      : '收貨資料'}
                                  </CardTitle>
                                </Col>
                                {request?.paymentStatus !== 'Drafted' &&
                                request?.paymentStatus !== 'InProgress' ? (
                                  <Col
                                    lg={6}
                                    xs={3}
                                    className="d-flex flex-row align-items-center justify-content-end"
                                  >
                                    <Button
                                      color="light"
                                      outline
                                      className="waves-effect"
                                      onClick={() =>
                                        setFieldValue(
                                          'isEditable',
                                          !values.isEditable,
                                        )
                                      }
                                    >
                                      {values.isEditable ? (
                                        'X'
                                      ) : (
                                        <i className="bx bx-edit-alt" />
                                      )}
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <div className="text-muted mb-4">
                                    {request?.paymentStatus === 'InProgress'
                                      ? '請核對費用及客戶單據無誤。當確認付款，客戶便可以讀取自取櫃密碼。'
                                      : '當客戶付款成功後，才可獲取取貨密碼。'}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} sm={12}>
                                  <SwitchField
                                    name="isFree"
                                    label="免費申請"
                                    // disabled={true}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                {!values.isFree ? (
                                  <Col lg={6} sm={12}>
                                    <InputField
                                      name="cost"
                                      label="費用"
                                      placeholder="輸入費用"
                                      type="number"
                                      disabled={!values.isEditable}
                                    />
                                    {request?.paymentStatus === 'InProgress' ? (
                                      <div className="text-muted mb-4">
                                        {renderTransactionFee()}
                                      </div>
                                    ) : null}
                                  </Col>
                                ) : null}

                                <Col lg={6} sm={12}>
                                  <DateField
                                    name="pickupDate"
                                    label="入酒日期"
                                    placeholder="入酒日期"
                                    disabled={!values.isEditable}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} sm={12}>
                                  <InputField
                                    name="passcode"
                                    label="自取櫃密碼"
                                    placeholder="輸入密碼"
                                    type="text"
                                    disabled={!values.isEditable}
                                  />
                                </Col>
                                <Col lg={6} sm={12}>
                                  <InputField
                                    name="lockerNumber"
                                    label="自取櫃編號"
                                    placeholder="輸入自取櫃編號"
                                    type="text"
                                    disabled={!values.isEditable}
                                  />
                                </Col>
                              </Row>
                              {request?.paymentStatus === 'InProgress' ? (
                                <Row>
                                  <Col lg={12}>
                                    <ImageField
                                      label="客戶單據"
                                      name="receiptImages"
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>
                              ) : null}
                              {request?.paymentStatus === 'Drafted' ||
                              request?.paymentStatus === 'Pending' ||
                              request?.paymentStatus === 'Failed' ||
                              request?.paymentStatus === 'Canceled' ||
                              request?.paymentStatus === 'InProgress' ? (
                                <Row>
                                  <Col lg={12} sm={12}>
                                    <InputField
                                      name="remarks"
                                      label="備註"
                                      placeholder="輸入備註"
                                      type="textarea"
                                      disabled={!values.isEditable}
                                    />
                                  </Col>
                                </Row>
                              ) : null}

                              {request?.paymentStatus === 'Drafted' ||
                              request?.paymentStatus === 'Pending' ||
                              request?.paymentStatus === 'Failed' ||
                              request?.paymentStatus === 'Canceled' ||
                              request?.paymentStatus === 'InProgress' ? (
                                <Row>
                                  <Col lg={12} sm={12}>
                                    <ImageField
                                      name="images"
                                      multiple
                                      label="收貨圖片"
                                      placeholder="收貨圖片"
                                      disabled={!values.isEditable}
                                    />
                                  </Col>
                                </Row>
                              ) : null}
                              {values.isEditable ? (
                                <div className="mt-3 d-flex flex-row-reverse">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="ms-1"
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <i className="bx bx-loader-circle bx-spin" />
                                    ) : (
                                      `${
                                        request?.paymentStatus === 'InProgress'
                                          ? '確認付款'
                                          : '提交'
                                      }`
                                    )}
                                  </Button>
                                </div>
                              ) : null}
                            </Form>
                          )}
                        </Formik>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}

          {packageReceiveRegistrationid ? (
            <Card>
              <CardBody>
                <Row>
                  <Col lg={6} sm={12}>
                    <CardTitle>信用卡付款紀錄</CardTitle>
                  </Col>
                  <Col
                    lg={6}
                    sm={12}
                    className="d-flex flex-row align-items-center justify-content-end"
                  >
                    <Button
                      className="btn btn-warning waves-effect waves-light btn-sm mb-2"
                      onClick={() => setIsHideLogs(!isHideLogs)}
                    >
                      {isHideLogs ? '查看更多' : '顯示較少'}
                      <i
                        className={
                          isHideLogs ? 'bx bx-caret-down' : 'bx bx-caret-up'
                        }
                      />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        columns={StripeLogsColumns()}
                        keyField="stripeLogid"
                        data={logs.length > 0 ? renderLogs() : []}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : null}
        </Container>
        <Dialog
          visible={deleteVisible}
          title="取消收貨申請"
          onClose={() => setDeleteVisible(false)}
          loading={deleteLoading}
          onConfirm={deleteConfirm}
        >
          <div>確定要取消收貨申請?</div>
        </Dialog>
      </div>
    </>
  );
};
